@import "../../mixins/global";

.FullPrescriptionForm__innerContainer__form .error {
  border: 1px solid #e32118 !important;
}
.FullPrescriptionForm__innerContainer__form .margin {
  margin: 0 10px !important;
}
.FullPrescriptionForm__innerContainer__form .both_values {
  @include rxc-small-mobile-only {
    margin-top: -8px !important;
  }
  @include rxc-mobile-only {
    flex-wrap: wrap;
    margin-top: 0px !important;
  }
}

.californiaDisplayNone {
  display: none !important;
}
.CalifornianInput {
  &__toggle {
    display: flex;
    padding: 0 6px;
    margin: 16px 0;
    @include rxc-mobile-only {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  &__toggleDesc {
    padding: 0 6px;
    margin-bottom: 56px;
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  &__ChooseTitle {
    padding: 0 6px;
    margin-bottom: 16px;
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  &__answerToggle {
    margin: 0 1.5rem;
  }
  &__containerEl {
    display: flex;
    margin-bottom: 1rem;
  }
  &__checkboxContainer {
    margin: 6px 12px 6px 6px;
  }
  &__rounded {
    position: relative;
    width: 16px;
    height: 16px;
    display: flex;
    justify-items: center;
    border-radius: 50px;
    border: 1px solid black;
    &__selected {
      width: 10px;
      height: 10px;
      background: #222;
      border-radius: 50px;
      margin: auto;
    }
  }
  &__title {
    margin-top: 6px;
    margin-bottom: 3px;
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    letter-spacing: 0.5px;

    color: #222222;
  }
  &__descEl {
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: 0.5px;

    color: #222222;
  }
  &__loaderContainer {
    margin: 22px 0;
  }
  &__switchContainer {
    display: flex;
    flex-direction: row;
    @include rxc-mobile-only {
      margin-top: 19px;
    }
  }
}
.CalifornianInput__nestedElement .PrescriptionUploadForm__button {
  background-color: #557b92 !important;
  align-items: center;
  display: flex;
  width: 260px;
  justify-content: center;
  &:hover {
    background-color: $rxc-blue-color-2 !important;
    font-weight: bold;
  }
}
.CalifornianInput__containerEl
  .FullPrescriptionForm__innerContainer__applyButton {
  @include rxc-mobile-only {
    width: 195px !important;
  }
}
.CalifornianInput__nestedElement thead th {
  background-color: white;
}

.FullPrescriptionForm {
  font-family: $rxc-clearly-font;

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #b8b8b8;

    @include rxc-desktop-only {
      margin: 16px 0 16px 0;
    }

    @include rxc-mobile-only {
      margin: 20px 0;
    }
  }

  &__subtitle {
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
  }

  &__readInfo {
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #1c4da1;
    & {
      cursor: pointer;
      text-decoration: underline;
      max-width: fit-content;
    }
    & img {
      vertical-align: sub;
    }
    & a {
      margin-left: 10px;
    }
  }

  &__innerContainer {
    display: flex;
    //font-family: $rxc-default-sofia-font;
    &__accountInfo {
      &__name {
        //font-family: $rxc-clearly-font;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 0px 0px 8px 0px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &__date {
        margin: 0px;
        //font-family: $rxc-clearly-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    &__apply {
      display: flex;
      position: relative;
      bottom: 0;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;

      &Button {
        margin-top: 20px;
        margin-bottom: 15px;
        gap: 8px;
        height: 40px;
        align-items: center !important;
        justify-content: center !important;
        display: flex !important;
        margin-bottom: 10px;
        background: #000000;
        border: none;
        border-radius: 4px;
        //font-family: $rxc-clearly-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: white;
        cursor: pointer;

        &__california {
          display: none;
        }

        @include rxc-desktop-only {
          width: 120px;
          padding: 9px 24px;
        }
        @include rxc-mobile-only {
          width: 100%;
          margin-bottom: 40px;
        }

        &:hover {
          box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
            0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
        }

        &_arrowRight {
          border: solid #ffffff !important;
          border-width: 0 1px 1px 0 !important;
          display: inline-block;
          padding: 1px 2px 2px 1px;
          margin-left: 3px;
          vertical-align: middle;
        }
      }
    }

    &_closed {
      //font-family: $rxc-default-sofia-font;
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;
      max-height: 0;
      & * {
        font-size: 14px !important;
      }
    }
    &_open {
      //font-family: $rxc-default-sofia-font;
      height: 100%;
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;

      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;
      & * {
        font-size: 14px !important;
      }
    }

    &__title {
      //font-size: 14px $rxc-default-sofia-font, Verdana, Arial, sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    &__form {
      margin-top: 32px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &_table {
        //font-family: $rxc-clearly-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        @include rxc-desktop-only {
          display: flex;
          flex-flow: row wrap;
        }
        @include rxc-small-desktop-only {
          display: flex;
          flex-flow: column wrap;
          flex: 0 0 100%;
        }
      }
      &_leftcol {
        @include rxc-desktop-only {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 24px 24px 20px 0px;
          flex-direction: column;
        }
        @include rxc-small-desktop-only {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          flex: 0 0 100%;
          align-self: flex-start;
          margin-bottom: 30px;
          margin-left: 107px;
        }
        @include rxc-medium-smaller-mobile-only {
          margin-left: 68px;
        }
        @include rxc-smaller-mobile-only {
          width: calc(100% - 67px);
          align-self: flex-end;
        }
        @include rxc-mobile-only {
          margin-left: 70px;
          margin-bottom: 8px;
        }
      }
      &_leftlabel {
        @include rxc-small-desktop-only {
          width: 107px;
          margin-right: 30px;
        }
        @include rxc-small-mobile-only {
          display: flex;
          margin-right: 15px;
          width: calc((100vw - 10px) / 3);
        }
        div {
          margin-right: 3px;
        }
      }

      &_col {
        @include rxc-desktop-only {
          flex-flow: column nowrap;
          display: flex;
          justify-content: center;
          margin-right: 16px;
        }

        @include rxc-small-desktop-only {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex: 0 0 100%;
          justify-content: flex-start;
          & > .FullPrescriptionForm__innerContainer__form_row {
            margin-right: 0;
          }
          &
            > .FullPrescriptionForm__innerContainer__form_row
            ~ .FullPrescriptionForm__innerContainer__form_row {
            margin-right: 30px;
          }
          // justify-content: space-evenly;
          // margin: 24px 15px 9px 0px;
        }
        @include rxc-small-mobile-only {
          & > .FullPrescriptionForm__innerContainer__form_row {
            width: 40px;
            margin-right: 8px;
          }
          &
            > .FullPrescriptionForm__innerContainer__form_row
            ~ .FullPrescriptionForm__innerContainer__form_row {
            width: calc((100vw - 20px) / 3);
            margin-right: 0;
            margin-left: 20px;
          }
        }
        @include rxc-smaller-mobile-only {
          & > .FullPrescriptionForm__innerContainer__form_row {
            margin-right: 15px;
          }
          &
            > .FullPrescriptionForm__innerContainer__form_row
            ~ .FullPrescriptionForm__innerContainer__form_row {
            margin-right: 0;
            margin-left: 12px;
          }
        }

        &.invisible {
          visibility: hidden;
          @include rxc-small-desktop-only {
            display: none;
          }
        }
        & > .FullPrescriptionForm__innerContainer__form_row {
          margin-bottom: 8px;
        }
        &
          > .FullPrescriptionForm__innerContainer__form_row
          ~ .FullPrescriptionForm__innerContainer__form_row {
          margin-bottom: 16px;
        }
      }
      &_row {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
        @include rxc-small-desktop-only {
          width: 107px;
        }
      }

      &_boldsr {
        margin-right: 5px;
        //font: 14px "sofia-pro-light", Verdana, Arial, sans-serif;
        font-size: 14px;
      }
      &_select {
        direction: ltr;
        text-align-last: left;
        position: relative;
        &:focus-within {
          outline: auto -webkit-focus-ring-color;
        }

        &_custom {
          border: 1px solid #6f6e6f;
          border-radius: 4px;
          width: 107px;
          @include rxc-small-mobile-only {
            width: 100%;
          }
        }
        &_prescription {
          border: 1px solid #767676;
          border-radius: 4px;
          width: 107px;
          position: relative;
          @include rxc-small-mobile-only {
            width: 100%;
          }
          &__add {
            &::after {
              content: "";
              width: 12px;
              height: 10px;
              position: absolute;
              top: 12px;
              right: 8px;
              background-size: contain;
              background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
              background-repeat: no-repeat;
              cursor: pointer;
              pointer-events: none;
            }
          }
          &__disabled {
            border: 1px solid #b8b8b8;
            border-radius: 4px;
            width: 107px;
            @include rxc-small-mobile-only {
              width: 100%;
            }
            & input {
              border: solid 1px transparent;
              &:focus {
                outline: none;
              }
            }
          }
          &__error {
            border: 1px solid #e32118 !important;
            border-radius: 4px;
            width: 107px;
            @include rxc-small-mobile-only {
              width: 100%;
            }
            & input {
              border: solid 1px transparent;
              &:focus {
                outline: none;
                &::-webkit-input-placeholder {
                  /* Chrome/Opera/Safari */
                  color: transparent;
                }
                &::-moz-placeholder {
                  /* Firefox 19+ */
                  color: transparent;
                }
                &:-ms-input-placeholder {
                  /* IE 10+ */
                  color: transparent;
                }
                &:-moz-placeholder {
                  /* Firefox 18- */
                  color: transparent;
                }
              }
              &[type="number" i] {
                padding: 1px 2px 1px 20px;
              }
            }
          }
          & input {
            border: solid 1px transparent;
            &:focus {
              outline: none;
            }
            &[type="number" i] {
              padding: 1px 2px 1px 15px;
              @include rxc-small-mobile-only {
                padding: 1px 2px 1px 0;
              }
            }
          }
        }

        & select {
          width: 100px;
          height: 30px;
          min-width: 85px;
          min-height: 30px;
          margin: 0;
          padding: 0 8px;
          display: flex;
          justify-content: start;
          background-color: #ffffff;
          border: solid 1px transparent;
          color: #333333;
          border-radius: 4px;
          -moz-appearance: none;
          -webkit-appearance: none;
          -ms-appearance: none;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;

          &:focus {
            outline: none;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #d8d8d8;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #767676;
            border-radius: 8px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #767676;
          }
          & input {
            font-size: 12px;
            line-height: 16px;
          }
          @include rxc-small-mobile-only {
            width: 100%;
          }
        }

        @include rxc-desktop-only {
          & #right_eye_axis {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            text-align: center;
            text-align-last: center;
          }
          & #right_eye_axis:disabled {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            background-color: #ffffff;
          }
          & #left_eye_axis {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            text-align: center;
            text-align-last: center;
          }
          & #left_eye_axis:disabled {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            background-color: #ffffff;
          }
        }
        @include rxc-small-desktop-only {
          & #right_eye_axis {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            text-align: center;
            text-align-last: center;
          }
          & #right_eye_axis:disabled {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            background-color: #ffffff;
          }
          & #left_eye_axis {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            text-align: center;
            text-align-last: center;
          }
          & #left_eye_axis:disabled {
            width: 100%;
            height: 30px;
            min-width: 85px;
            min-height: 30px;
            border-radius: 4px;
            background-color: #ffffff;
          }
        }
      }
    }

    &__warning {
      width: 365px;
      //font: 12px "sofia-pro", Verdana, Arial, sans-serif !important;
      font-size: 12px;
      line-height: 1.23;
      color: #192c50;
      font-size: 12px !important;
    }
  }

  &__clearButtonError {
    //font-family: $rxc-default-sofia-font;
    margin-right: 35px;
    margin-top: 20px;
    float: right;
    margin: 0;
    font-size: 12px !important;
    height: 24px;
    padding: 0px 22px;
  }
}

.PupillaryDistance {
  &__checkboxContainer {
    display: flex;
    align-items: center;
    margin-left: 20px !important;
    margin-top: 2.5px;
  }

  &__checkBoxText {
    //font-family: $rxc-default-sofiaLight-font;
    font-size: 14px;
    line-height: 15px;
    margin-left: 10px;
  }

  &__warning {
    //font-family: $rxc-default-sofia-font;
    display: flex;
    background-color: #e7eef0;
    margin-top: 25px;
    margin-bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    padding: 0px 15px 0px 15px;
    min-width: 100%;

    &__tooltip {
      //font-family: $rxc-default-sofia-font;
      font-size: $rxc-sun-default-12-fontSize;
      z-index: 1;
      width: fit-content;
      justify-content: center;
      position: absolute;
      margin-top: 3.8%;
      left: 20px;
      max-width: 289px;
      padding: 23px 26px 23px 31px;
      background-color: $rxc-white-color-1;
      -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      border-radius: 4px;

      @include rxc-mobile-only {
        margin-top: 10px;
      }
    }
    &__icon {
      position: absolute !important;
      top: 13px;
      height: 14px;
      content: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/info-img.png);
    }
    &__text {
      //font-family: $rxc-default-sofia-font;
      font-weight: 400;
      font-size: 12px;
      padding: 10px 30px 10px 30px;
      display: block !important;
      line-height: 1.33;
      letter-spacing: 0.16px;
      white-space: break-spaces;
      color: #557480;
      @include rxc-desktop-only {
        max-width: 80%;
      }
      &__withAfter {
        font-size: 12px;
        padding: 10px 30px 10px 30px;
        display: block !important;
        line-height: 1.33;
        letter-spacing: 0.16px;
        white-space: break-spaces;
        color: #557480;
        pointer-events: none;
        &::after {
          content: "measure your PD";
          color: #244c5a;
          text-decoration: underline;
          pointer-events: all;
        }
      }
    }
  }

  &__optionsContainer {
    //font-family: $rxc-default-sofia-font;
    display: flex;
    align-items: center;
    &__select_container {
      position: relative;
      @include rxc-small-mobile-only {
        width: calc(100vw / 4) !important;
      }
      &.margin {
        @include rxc-small-mobile-only {
          margin: 0 6px !important;
        }
      }
      & select {
        cursor: pointer;
        display: flex;
        justify-content: start;
        width: 107px !important;
        padding-left: 10px;
        height: 30px;
        background-color: #ffffff;
        border: solid 1px #000000;
        color: #333333;
        border-radius: 4px;
        align-items: center;
        line-height: 16px;
        -webkit-appearance: none;
        @include rxc-small-mobile-only {
          width: 100% !important;
        }
        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #767676;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #767676;
        }
      }
      & select.__DoubleSelect {
        .error {
          border-color: #e32118;
        }
        @include rxc-small-mobile-only {
          width: 100% !important;
        }
      }
      &::after {
        content: "";
        width: 12px;
        height: 10px;
        position: absolute;
        top: 12px;
        right: 8px;
        background-size: contain;
        background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        pointer-events: none;
      }
    }
  }

  &__checkboxLabel {
    //font-family: $rxc-default-sofia-font;
    margin: 0 !important;
  }

  &__pdValueNotSelectedWarning {
    color: #e32118;
    //font-family: SofiaPro;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.16px;
    margin-top: 16px;
    pointer-events: none;

    &::after {
      content: attr(data-content);
      color: #244c5a;
      text-decoration: underline;
      pointer-events: all;
    }
  }
}

.MoreOptions {
  &__accordionBtnContainer {
    margin: 24px 0;
    display: none;
    @include rxc-mobile-only {
      width: 100%;
      margin-top: 20px;
    }

    &__accordionBtnClosed {
      text-decoration: underline;
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #1c4da1;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid $rxc-blue-skin-default;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: -4px;
      }
    }

    &__accordionBtnOpen {
      text-decoration: underline;
      border: none;
      background: none;
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
      padding: 0;
      color: #1c4da1;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(-135deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid $rxc-blue-skin-default;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: 1px;
      }
    }
  }

  &__checkboxContainer {
    display: none;
  }
}

.CustomCheckbox {
  &__checkbox {
    -webkit-appearance: none;
    border: 1px solid #6f6e6f;
    background-color: white;
    min-width: 16px !important;
    height: 16px !important;
    border-radius: 2px !important;
    display: inline-block;
    position: relative;

    &.marginTop {
      margin-top: 4px;
    }

    &.checked {
      background: #1c4da1;
      border: none !important;

      &:after {
        content: "" !important;
        position: absolute !important;
        width: 10px;
        height: 10px;
        top: 3px !important;
        left: 3px !important;
        background-size: contain;
        background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/check.png);
        background-repeat: no-repeat;
      }
    }

    &.disabled {
      background-color: #f1f2f2;
      border: 1px solid #b8b8b8;
    }
  }
}

.rxc-prescription-select-table > div > div {
  margin-right: -2px !important;
}

.AgreementCheckbox {
  &__container {
    display: flex;
    align-items: flex-start;

    &.invisible {
      display: none;
    }
  }

  &__text {
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 12px;
    &.disabled {
      color: #b8b8b8;
    }
  }
}

.IncompatibleError {
  &__container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    //font-family: $rxc-clearly-font;
    vertical-align: middle;
    border: 1px solid #d51c29;
    border-radius: 8px;
    padding: 16px 24px;
    gap: 16px;
    align-items: center !important;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    background-color: white;

    @include rxc-desktop-only {
      min-width: 50%;
      max-width: 100%;
    }

    @include rxc-mobile-only {
      min-width: 70%;
      max-width: 100%;
    }

    &__firstline {
      color: #d51c29;
      text-align: center;
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    &__firstlineClear {
      color: #d51c29;
      margin-bottom: 16px;
      text-align: center;
      max-width: 70%;
    }

    &__alternative {
      margin-bottom: 16px;
      text-align: center;
      display: none;
    }

    &__shopCompatibleBtn {
      width: auto;
      height: 40px;
      border: none;
      border-radius: 4px !important;
      padding: 9px 24px;
      gap: 8px;
      background-color: #222222 !important;
      color: #ffffff !important;
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        justify-content: center;
        margin-top: 0px;
        //font: 14px "sofia-pro", Verdana, Arial, sans-serif;
        font-size: 14px;
      }
    }

    &__clearAll {
      height: 40px;
      border-radius: 100px;
      background-color: white;
      color: #222222;
      text-transform: uppercase;
      //font-family: "sofia-pro-light";
      font-weight: 600;
      //font: 16px "sofia-pro", Verdana, Arial, sans-serif;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      padding: 8px 32px;
      align-self: center;
      border: solid 1px #222222;
      letter-spacing: 0.5px;
      width: 100%;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 0 1px $rxc-grey2-sun-default;
        background-color: rgba(34, 34, 34, 0.05) !important;
      }
    }
  }
}

.SimpleTextError {
  &__message {
    //font-family: $rxc-default-sofia-font;
    color: #e32118;
    margin: 10px 0 24px 0;
    font-size: 12px !important;
    line-height: 16px;
  }
}

.Tooltip {
  z-index: 2147483647;
  &__tooltip {
    //font-family: $rxc-default-sofia-font;
    font-size: 12px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 13%;
    max-width: 289px;
    padding: 30px;
    opacity: 1 !important;
    background-color: $rxc-white-color-1;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: #222222;

    @include rxc-mobile-only {
      margin-top: 10%;
    }
  }

  &__text {
    display: flex;
    margin-bottom: 8px;
    white-space: pre-line;
    vertical-align: bottom;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}
.FileUpload {
  min-height: 20vh;
  & .PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent {
    //font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: $rxc-blue-skin-default;
  }
}
.SaveAccountPrescription {
  &__label {
    margin: 0 0px 4px 10px;
    //font-family: $rxc-clearly-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #3d3c3c;
    text-transform: capitalize;
  }
  &__inputContainer {
    margin-bottom: 36px;
    position: relative;
    &__input {
      position: relative;
      padding: 12px 16px;
      border: 1px solid #6f6e6f;
      border-radius: 4px;
      margin-bottom: 4px;
      width: 100%;

      &:focus {
        outline: none;
      }
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__errorIcon {
      position: absolute;
      top: 10px;
      right: 13px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.6657 5.33333H7.33237V10.6667H8.6657V5.33333Z' fill='black'/%3e%3cpath d='M8.6657 12H7.33237V13.3333H8.6657V12Z' fill='black'/%3e%3cpath d='M15.7624 12.916L9.78137 1.16766C9.41037 0.43933 8.7047 0.0749969 7.99904 0.0749969C7.29337 0.0749969 6.5877 0.43933 6.2167 1.16766L0.235704 12.916C-0.482963 14.3273 0.54237 16 2.12637 16H13.872C15.4557 16 16.481 14.3273 15.7624 12.916ZM14.5437 14.2903C14.4744 14.4037 14.27 14.6667 13.8717 14.6667H2.12637C1.72804 14.6667 1.5237 14.4037 1.45437 14.2903C1.38504 14.1773 1.24337 13.8757 1.42404 13.521L7.40504 1.77266C7.57237 1.444 7.87604 1.40833 7.99904 1.40833C8.12204 1.40833 8.4257 1.44366 8.59304 1.77266L14.574 13.521C14.7547 13.876 14.613 14.1773 14.5437 14.2903Z' fill='black'/%3e%3c/svg%3e");
    }
    &__error {
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #d32113;
      margin-left: 10px;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &__label {
      margin-left: 12px;
      //font-family: $rxc-clearly-font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      &.disabled {
        color: #b8b8b8;
      }
    }
  }
}
.redBorder {
  border: 1px solid #d32113;
}
