@import "../../mixins/global";

.PrescriptionUploadForm__PupillaryDistance__optionsContainer .margin {
  margin: 0 10px !important;
}

.PrescriptionUploadForm {
  &__subtitle {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 32px;
    @include rxc-mobile-only {
      margin-bottom: 24px;
    }
  }

  &__submitContainer {
    display: flex;
    position: relative;
    height: 100%;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;

    @include rxc-mobile-only {
      margin-top: 20px;
    }
  }

  &__submitButton {
    width: fit-content;
    height: 40px;
    border: unset;
    padding: 9px 24px;
    gap: 8px;
    border-radius: 100px;
    margin-right: -8px;
    background: #000000;
    border-radius: 4px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-align: center;
    color: white;
    text-transform: lowercase;
    cursor: pointer;
    &::first-letter {
      text-transform: uppercase;
    }

    @include rxc-mobile-only {
      width: 100%;
      margin-right: unset;
    }
    &:hover {
      box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
        0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
    }
  }

  &__button {
    width: fit-content;
    height: 40px;
    padding: 9px 24px;
    gap: 8px;
    border-radius: 4px;
    background-color: #000000;
    border: none;
    position: relative;
    z-index: 1;
    text-align: center;
    color: #ffffff;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__UploadBox {
    &__container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: fit-content;
      padding: 24px;
      border: solid 1px #b8b8b8;
      border-radius: 8px;
      min-height: 160px;
      background-color: #ffffff;
      @include rxc-mobile-only {
        border: 1px solid #000000;
      }
      &.error {
        border: solid 2px #d51c29;
        @include rxc-mobile-only {
          border: 1px solid #d51c29;
        }
      }

      &__spinnerContainer {
        display: flex;
        justify-content: center;
      }

      &__topContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      &__bottomContainer {
        width: 100%;
        margin-top: 8px;
      }

      &__changeMethod {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        color: #1c4da1;
        text-decoration: underline;
        @include rxc-mobile-only {
          display: none;
        }
      }

      &__changeMethodMobile {
        display: none;

        @include rxc-mobile-only {
          display: unset;
          font-family: "Mulish";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;
          margin-top: 16px;
          align-self: flex-end;
          color: #1c4da1;
          text-decoration: underline;
        }
      }
    }
  }

  &__UploadResult {
    &__container {
      display: flex;
      align-items: center;
      font-family: "Mulish";
      font-style: normal;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__unsuccessfulIconContainer {
      margin-right: 10px;
      margin-top: 2px;
    }

    &__successText {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin-left: 10px;
      color: #005d30;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__errorText {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #e32118;
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  &__FilePreview {
    &__container {
      display: flex;

      &__filePreview {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 84px;
        height: 90px;
        margin-right: 15px;
        cursor: pointer;

        & .Loader__container {
          margin-left: 0;
        }

        &.noPreview {
          cursor: unset !important;
        }

        img {
          max-width: 84px;
          max-height: 90px;
          margin: auto;
        }
      }

      &__fileInfo {
        display: flex;
        flex-flow: column;
        margin-top: 10px;
        font-family: "Mulish";
        font-style: normal;
        &__nameAndSize {
          font-weight: 400;
          line-height: 21px;
          font-size: 14px;
          word-break: break-all;
        }

        &__uploadDifferent {
          margin-top: 8px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          text-decoration: underline;
          cursor: pointer;
          color: #1c4da1;
        }
      }
    }
  }

  &__UploadError {
    &__container {
      &__message {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 24px;
      }
    }
  }

  &__CustomCheckbox {
    &__checkbox {
      -webkit-appearance: none;
      background-color: white;
      border: 1px solid rgb(118, 118, 118);
      min-width: 16px !important;
      height: 16px !important;
      border-radius: 2px !important;
      display: inline-block;
      position: relative;

      &.marginTop {
        margin-top: 4px;
      }

      &.checked {
        background: black;
        border: none !important;

        &:after {
          content: "" !important;
          position: absolute !important;
          width: 10px;
          height: 10px;
          top: 3px !important;
          left: 3px !important;
          background-size: contain;
          background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/check.png);
          background-repeat: no-repeat;
        }
      }

      &:hover {
        border: 1px solid rgb(90, 90, 90);
      }
    }
  }

  &__Separator {
    display: block;
    width: 100%;
    height: 1px;
    margin: 24px 0px 16px 0px;
    background-color: #b8b8b8;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}
.StepTitle__container {
  @include rxc-mobile-only {
    padding-bottom: 0px !important;
    margin-bottom: 8px !important;
  }
}
