@charset "UTF-8";

@import url("https://p.typekit.net/p.css?s=1&k=tzs3jig&ht=tk&f=24541.24542.24543.24544.24545.24546.24549.24552&a=16989191&app=typekit&e=css");

@font-face {
  font-family: "sofia-pro-extra-light";
  src: url("https://use.typekit.net/af/f98804/00000000000000003b9b46e9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f98804/00000000000000003b9b46e9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f98804/00000000000000003b9b46e9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "sofia-pro-extra-light-italic";
  src: url("https://use.typekit.net/af/9a53d6/00000000000000003b9b46ea/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9a53d6/00000000000000003b9b46ea/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9a53d6/00000000000000003b9b46ea/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "sofia-pro-light";
  src: url("https://use.typekit.net/af/f5f3eb/00000000000000003b9b46eb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f5f3eb/00000000000000003b9b46eb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f5f3eb/00000000000000003b9b46eb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "sofia-pro-light-italic";
  src: url("https://use.typekit.net/af/ebb75a/00000000000000003b9b46ec/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ebb75a/00000000000000003b9b46ec/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ebb75a/00000000000000003b9b46ec/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "sofia-pro-medium";
  src: url("https://use.typekit.net/af/227c77/00000000000000003b9b46ef/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/227c77/00000000000000003b9b46ef/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/227c77/00000000000000003b9b46ef/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "sofia-pro-medium-italic";
  src: url("https://use.typekit.net/af/736c05/00000000000000003b9b46f0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/736c05/00000000000000003b9b46f0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/736c05/00000000000000003b9b46f0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "sofia-pro-semibold";
  src: url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "sofia-pro-bold";
  src: url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4526e4/00000000000000003b9b46f1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "sofia-pro-semibold-italic";
  src: url("https://use.typekit.net/af/4cc823/00000000000000003b9b46f2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4cc823/00000000000000003b9b46f2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4cc823/00000000000000003b9b46f2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "minerva-modern";
  src: url("https://use.typekit.net/af/2d15e1/00000000000000003b9afd25/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2d15e1/00000000000000003b9afd25/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2d15e1/00000000000000003b9afd25/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "minerva-modern-italic";
  src: url("https://use.typekit.net/af/e6158c/00000000000000003b9afd26/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e6158c/00000000000000003b9afd26/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e6158c/00000000000000003b9afd26/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "sofia-pro";
  src: url("./fontFiles/Sofia-Pro.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Costa-Headline";
  src: url("./fontFiles/Costa-Headline.a148fbbd.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Futura-Bold";
  src: url("./fontFiles/futura-bold-03-webfont.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./fontFiles/proximanova-regular-webfont.6ca94861.woff2")
    format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./fontFiles/proximanova-bold-webfont.a9ff3e32.woff2")
    format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("./fontFiles/Barlow-Regular.eot"),
    url("./fontFiles/Barlow-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fontFiles/Barlow-Regular.woff2") format("woff2"),
    url("./fontFiles/Barlow-Regular.woff") format("woff"),
    url("./fontFiles/Barlow-Regular.ttf") format("truetype"),
    url("./fontFiles/Barlow-Regular.svg#Barlow-Regular") format("svg");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Bold";
  src: url("./fontFiles/Barlow-Bold.eot"),
    url("./fontFiles/Barlow-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fontFiles/Barlow-Bold.woff2") format("woff2"),
    url("./fontFiles/Barlow-Bold.woff") format("woff"),
    url("./fontFiles/Barlow-Bold.ttf") format("truetype"),
    url("./fontFiles/Barlow-Bold.svg#Barlow-Bold") format("svg");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "noto_sansregular";
  src: url("./fontFiles/NotoSans-Regular-webfont.woff") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "noto_sansbold";
  src: url("./fontFiles/NotoSans-Bold-webfont.woff") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Space Mono";
  src: url("./fontFiles/SpaceMono.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Space Mono Bold";
  src: url("./fontFiles/SpaceMonoBold.eot"),
    url("./fontFiles/SpaceMonoBold.eot?#iefix") format("embedded-opentype"),
    url("./fontFiles/SpaceMonoBold.woff2") format("woff2"),
    url("./fontFiles/SpaceMonoBold.woff") format("woff"),
    url("./fontFiles/SpaceMonoBold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Regular";
  src: url("./fontFiles/AvenirNext-Regular.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("./fontFiles/AvenirNext-Medium.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Semibold";
  src: url("./fontFiles/OpenSans-Semibold.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Bold";
  src: url("./fontFiles/AvenirNext-Bold.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Heavy";
  src: url("./fontFiles/AvenirNext-Heavy.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./fontFiles/Oswald-V34-Vietnamese-Latin-500.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Oswald-Medium";
  src: url("./fontFiles/Oswald-Medium.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./fontFiles/Lato-V16-Latin-Regular.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("./fontFiles/Lato-Regular.woff2") format("woff");
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("./fontFiles/Lato-Bold.woff2") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Orbi";
  src: url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.eot");
  src: url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/907f8dacd0378ba95ac7fb0031dc5839.svg#OrbiW01-Narrow")
      format("svg");
}

@font-face {
  font-family: "Open Sans";
  src: url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.eot");
  src: url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.svg#Open Sans")
      format("svg");
}
@font-face {
  font-family: "Tungsten";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Tungsten.ttf") format("truetype");
}
@font-face {
  font-family: "Tungsten Semibold";
  font-style: normal;
  font-weight: 373;
  src: url("./fontFiles/Tungsten-Semibold.woff2") format("truetype");
}

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Interstate-regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MorePro";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/MorePro-CondBook.woff2") format("woff2");
}

@font-face {
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Regular.woff") format("woff");
}

@font-face {
  font-family: "Lato Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Italic.woff") format("woff");
}

@font-face {
  font-family: "Lato Hairline";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Hairline.woff") format("woff");
}

@font-face {
  font-family: "Lato Hairline Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-HairlineItalic.woff") format("woff");
}

@font-face {
  font-family: "Lato Light";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Light.woff") format("woff");
}

@font-face {
  font-family: "Lato Light Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Lato Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Bold.woff") format("woff");
}

@font-face {
  font-family: "Lato Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Lato Black";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-Black.woff") format("woff");
}

@font-face {
  font-family: "Lato Black Italic";
  font-style: normal;
  font-weight: normal;
  src: url("./fontFiles/Lato-BlackItalic.woff") format("woff");
}

/* Roboto Family */

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url("./fontFiles/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Bold";
  font-weight: bold;
  font-style: normal;
  src: url("./fontFiles/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Light";
  font-weight: 300;
  font-style: normal;
  src: url("./fontFiles/Roboto-Light.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Medium";
  font-weight: 500;
  font-style: normal;
  src: url("./fontFiles/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto-Thin";
  font-weight: 100;
  font-style: normal;
  src: url("./fontFiles/Roboto-Thin.woff") format("woff");
}

/* Config font Neue Haas Unica */

@font-face {
  font-family: "Neue Haas Unica Black";
  src: url("./fontFiles/NeueHaasUnica-Black.eot");
  src: url("./fontFiles/NeueHaasUnica-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Black.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Black.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Medium";
  src: url("./fontFiles/NeueHaasUnica-Medium.eot");
  src: url("./fontFiles/NeueHaasUnica-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Medium.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Medium.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Unica Bold Italic";
  src: url("./fontFiles/NeueHaasUnica-BoldItalic.eot");
  src: url("./fontFiles/NeueHaasUnica-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-BoldItalic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-BoldItalic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Unica ExtBlkIta";
  src: url("./fontFiles/NeueHaasUnica-ExtBlkIta.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtBlkIta.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtBlkIta.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtBlkIta.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtBlkIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Unica Extra Bold";
  src: url("./fontFiles/NeueHaasUnica-ExtraBold.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtraBold.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtraBold.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Medium Italic";
  src: url("./fontFiles/NeueHaasUnica-MediumItalic.eot");
  src: url("./fontFiles/NeueHaasUnica-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-MediumItalic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-MediumItalic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Extra Bold Italic";
  src: url("./fontFiles/NeueHaasUnica-ExtraBoldItalic.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtraBoldItalic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtraBoldItalic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Italic";
  src: url("./fontFiles/NeueHaasUnica-Italic.eot");
  src: url("./fontFiles/NeueHaasUnica-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Italic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Italic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Light";
  src: url("./fontFiles/NeueHaasUnica-Light.eot");
  src: url("./fontFiles/NeueHaasUnica-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Light.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Light.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas ExtKtIta";
  src: url("./fontFiles/NeueHaasUnica-ExtLtIta.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtLtIta.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtLtIta.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtLtIta.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtLtIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Bold";
  src: url("./fontFiles/NeueHaasUnica-Bold.eot");
  src: url("./fontFiles/NeueHaasUnica-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Bold.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Bold.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Thin Italic";
  src: url("./fontFiles/NeueHaasUnica-ThinItalic.eot");
  src: url("./fontFiles/NeueHaasUnica-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ThinItalic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ThinItalic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Light Italic";
  src: url("./fontFiles/NeueHaasUnica-LightItalic.eot");
  src: url("./fontFiles/NeueHaasUnica-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-LightItalic.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-LightItalic.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Extra Light";
  src: url("./fontFiles/NeueHaasUnica-ExtraLight.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtraLight.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtraLight.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Regular";
  src: url("./fontFiles/NeueHaasUnica-Regular.eot");
  src: url("./fontFiles/NeueHaasUnica-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Regular.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Regular.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Extra Black";
  src: url("./fontFiles/NeueHaasUnica-ExtraBlack.eot");
  src: url("./fontFiles/NeueHaasUnica-ExtraBlack.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-ExtraBlack.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-ExtraBlack.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-ExtraBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Neue Haas Bold Italic";
  src: url("./fontFiles/NeueHaasUnica-BoldItalic_1.eot");
  src: url("./fontFiles/NeueHaasUnica-BoldItalic_1.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-BoldItalic_1.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-BoldItalic_1.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-BoldItalic_1.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Neue Haas Thin";
  src: url("./fontFiles/NeueHaasUnica-Thin.eot");
  src: url("./fontFiles/NeueHaasUnica-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./fontFiles/NeueHaasUnica-Thin.woff2") format("woff2"),
    url("./fontFiles/NeueHaasUnica-Thin.woff") format("woff"),
    url("./fontFiles/NeueHaasUnica-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "helvetica-for-target";
  src: url("./fontFiles/helveticafortarget-rm.otf") format("opentype");
}

@font-face {
  font-family: "helvetica-for-target-bold";
  src: url("./fontFiles/helveticafortarget-bd.otf") format("opentype"),
    url("./fontFiles/helveticafortarget-bd.woff2") format("woff2"),
    url("./fontFiles/helveticafortarget-bd.woff") format("woff");
  font-style: bold;
}

@font-face {
  font-family: "helvetica-for-target-medium";
  src: url("./fontFiles/helveticafortarget-md.otf") format("opentype");
}

//sgh

@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("./fontFiles/HelveticaNeue-Light.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue-Regular";
  src: url("./fontFiles/HelveticaNeue.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("./fontFiles/HelveticaNeue-Medium.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("./fontFiles/HelveticaNeue-Bold.ttf") format("truetype");
  font-style: normal;
}

// David Clulow

@font-face {
  font-family: "Montserrat-Black";
  src: url("./fontFiles/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./fontFiles/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url("./fontFiles/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url("./fontFiles/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./fontFiles/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./fontFiles/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url("./fontFiles/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("./fontFiles/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./fontFiles/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./fontFiles/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url("./fontFiles/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-LightItalic";
  src: url("./fontFiles/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("./fontFiles/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url("./fontFiles/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fontFiles/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("./fontFiles/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url("./fontFiles/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url("./fontFiles/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CABOTO-Bold";
  src: url("./fontFiles/CABOTO-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaStd-Bold";
  src: url("./fontFiles/FuturaStd-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CABOTO-Regular";
  src: url("./fontFiles/CABOTO-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FuturaStd-Book";
  src: url("./fontFiles/FuturaStd-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "berlingltstd-bold";
  src: url("./fontFiles/berlingltstd-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "berlingltstd-bolditalic";
  src: url("./fontFiles/berlingltstd-bolditalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "berlingltstd-italic";
  src: url("./fontFiles/berlingltstd-italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "berlingltstd-roman";
  src: url("./fontFiles/berlingltstd-roman.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "COBOTO";
  src: url("./fontFiles/CABOTO-Regular.otf") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Mulish";
  src: url("./fontFiles/Mulish-Regular.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Mulish";
  src: url("./fontFiles/Mulish-Bold.ttf") format("opentype");
  font-weight: 700;
}
