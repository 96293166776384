$rxc-breakpoint-small-desktop: 1350px;
$rxc-breakpoint-desktop: 1200px;
$rxc-breakpoint-mobile: 1199px;
$rxc-breakpoint-small: 760px;
$rxc-breakpoint-smartphone: 500px;
$rxc-breakpoint-medium-smaller: 414px;
$rxc-breakpoint-smaller: 360px;

// Akamai
$globalImageUrl: "https://assets.lenscrafters.com/extra/image/LensCrafters/global/";
$projectImageUrl: "https://images.ray-ban.com/extra/image/rxc/images/";
$raybanImageUrl: "https://images.ray-ban.com/extra/image/rxc/brands/RayBan/";

$rxc-font-button: 12px;
$rxc-default-font-size: 14px;

$rxc-sun-default-12-fontSize: 12px;
$rxc-sun-default-14-fontSize: 14px;
$rxc-sun-default-16-fontSize: 16px;
$rxc-sun-default-minerva-fontSize: 32px;
$rxc-sun-default-mobiletitle-fontSize: 20px;

//NUOVO tutto ciò con font
$rxc-default-font: "noto_sansregular";
$rxc-default-font-bold: "noto_sansbold";
$rxc-default-font-gl: "Space Mono";
$rxc-default-font-gl-bold: "Space Mono Bold";

$rxc-osi-MorePro-font: "MorePro";

$rxc-glasses-DMSans-font: "DM Sans Regular";

$rxc-sun-default-minerva-font: "minerva-modern";
$rxc-default-sofia-font: "sofia-pro";
$rxc-sun-default-sofia-font: "sofia-pro";
$rxc-default-sofiaLight-font: "sofia-pro-light";
$rxc-default-sofiaBold-font: "sofia-pro-semibold";
$rxc-sun-default-sofiaBold-font: "sofia-pro-semibold";

$rxc-Roboto-font: "Roboto";
$rxc-Roboto-Medium-font: "Roboto-Medium";
$rxc-Roboto-Bold-font: "Roboto-Bold";

$rxc-osi-openSans-font: "Open Sans";

$rxc-costa-Interstate-font: "Interstate";
$rxc-costa-Tungsten-font: "Tungsten";
$rxc-costa-TungstenSemibold-font: "Tungsten Semibold";
$rxc-osi-openSans-font: "Open Sans";
$rxc-rayban-lato-font: "Lato";
$rxc-rayban-lato-bold-font: "Lato-Bold";
$rxc-rayban-oswaldMed-font: "Oswald-Medium";

$rxc-oo-avenir-font: "AvenirNext-Regular";
$rxc-oo-avenirBold-font: "AvenirNext-Bold";

$rxc-opsm-barlow-font: "Barlow";
$rxc-opsm-barlow-bold-font: "Barlow Bold";

$rxc-sgh-helvetica: "HelveticaNeue";
$rxc-sgh-helvetica-light: "HelveticaNeue-Light";
$rxc-sgh-helvetica-regular: "HelveticaNeue-Regular";
$rxc-sgh-helvetica-medium: "HelveticaNeue-Medium";
$rxc-sgh-helvetica-bold: "HelveticaNeue-Bold";

$rxc-to-helvetica: "helvetica-for-target";
$rxc-to-helvetica-medium: "helvetica-for-target-medium";
$rxc-to-helvetica-bold: "helvetica-for-target-bold";

$rxc-white-color-1: #ffffff;
$rxc-black-color-1: #000000;
$rxc-black-trasparent-color: #00000014;
$rxc-yellow-color-1: #f6ee16;
$rxc-yellow-color-2: #f6ee60;

$rxc-grey-color-1: #f6f6f6;
$rxc-grey-color-2: #e1e1e1;
$rxc-grey-color-3: #333333;
$rxc-grey-color-4: #d3d3d3;
$rxc-grey-color-5: #d7d7d7;
$rxc-grey-color-6: rgba(51, 51, 51, 0.6);
$rxc-grey-color-7: #666666;
$rxc-grey-color-8: rgba(255, 255, 255, 0.8);
$rxc-grey-color-9: #767676;

$rxc-green-color-1: #2c7d50;
$rxc-blue-color-1: #326fa8;
$rxc-blue-color-2: #192c50;
$rxc-blue-color-3: #367ebd;
$rxc-blue-color-4: #88abba;
$rxc-blue-color-5: #2089c8;
$rxc-red-color-1: #d0021b;
$rxc-red-color-2: #e80c00;
$rxc-pink-color-1: #e71d86;

$rxc-grey-sun-default: #333333;
$rxc-grey2-sun-default: #222222;
$rxc-blue-skin-default: #557b92;
$rxc-grey-separator: #d8d8d8;
$rxc-blue-badge: #192c50;
$rxc-blue-osi: #0289c9;

$rxc-violet1-glasses: #e5ebfe;
$rxc-violet2-glasses: #575d9b;
$rxc-violet3-glasses: #323666;

$rxc-grey-costa: #dde5ed;
$rxc-fontColor-costa: #182446;
$rxc-blue-costa: #007ac2;
$rxc-green-costa: #1e7e34;
$rxc-black-rayban: #1f1f24;
$rxc-orange-opsm: #e1523e;
$rxc-black-opsm: #2d2a26;
$rxc-gold-opsm: #b58b34;
$rxc-red-oakley: #c83430;

/* Color palette Arnette skin */
$rxc-arnette--geko-green: #94fa66;
$rxc-arnette--black: #000;
$rxc-arnette--error-red-lightmode: #9b0800;
$rxc-arnette--lobster-pink: #df756f;
$rxc-arnette--error-red-darkmode: #ff635a;
$rxc-arnette--warm-grey: #ebe9e4;
$rxc-arnette--grey-lightmode: #4b4b4b;
$rxc-arnette--grey-darkmode: #a1a0a0;

/* Fonts used in Arnette skin */
$rxc-arnette--font-regular: "Neue Haas Regular";
$rxc-arnette--font-bold: "Neue Haas Bold";
$rxc-arnette--font-extra-bold: "Neue Haas Extra Bold";
$rxc-arnette--font-thin: "Neue Haas Thin";
$rxc-arnette--font-medium: "Neue Haas Medium";
$rxc-arnette--font-black: "Neue Haas Black";
$rxc-arnette--font-light: "Neue Haas Light";
$rxc-arnette--font-extra-light: "Neue Haas Extra Light";
$rxc-arnette--font-bold-italic: "Neue Haas Bold Italic";

/* Color palette David Clulow skin */
$rxc-david--overlay: #33333399;
$rxc-david--blue-primary: #557b92;
$rxc-david--warning-azure: #bce7df;
$rxc-david--button-text: #83c8bb;
$rxc-david--petrol: #244c5a;
$rxc-david--dark-blue: #00303c;
$rxc-david--error: #d32113;
$rxc-david--success: #247534;
$rxc-david--dark-border: #767676;
$rxc-david--dark: #222;
$rxc-david--medium-gray: #d8d8d8;
$rxc-david--background-gray: #f6f6f6;
$rxc-david--cream: #f0eae2;
$rxc-david--white: #fff;
$rxc-david--white-trasparent: #ffffffcc;

/* Fonts used in David Clulow skin */
$rxc-david-font-black: "Montserrat-Black";
$rxc-david-font-black-italic: "Montserrat-BlackItalic";
$rxc-david-font-bold: "Montserrat-Bold";
$rxc-david-font-bold-italic: "Montserrat-BoldItalic";
$rxc-david-font-extra-bold: "Montserrat-ExtraBold";
$rxc-david-font-extra-bold-italic: "Montserrat-ExtraBoldItalic";
$rxc-david-font-extra-light: "Montserrat-ExtraLight";
$rxc-david-font-extra-light-italic: "Montserrat-ExtraLightItalic";
$rxc-david-font-italic: "Montserrat-Italic";
$rxc-david-font-light: "Montserrat-Light";
$rxc-david-font-light-italic: "Montserrat-LightItalic";
$rxc-david-font-medium: "Montserrat-Medium";
$rxc-david-font-medium-italic: "Montserrat-MediumItalic";
$rxc-david-font-regular: "Montserrat-Regular";
$rxc-david-font-semi-bold: "Montserrat-SemiBold";
$rxc-david-font-semi-bold-italic: "Montserrat-SemiBoldItalic";
$rxc-david-font-thin: "Montserrat-Thin";
$rxc-david-font-thin-italic: "Montserrat-ThinItalic";

// Color pallette Persol skin

$rxc-persol--stone-blue: #233d5d;
$rxc-persol--paypalbutton-text: #ffc439;
$rxc-persol--error: #d0021b;
$rxc-persol--success: #417505;
$rxc-persol--stroke-gray: #bfbfbf;
$rxc-persol--dark-gray: #6d6d6d;
$rxc-persol--background-gray: #f7f7f7;
$rxc-persol--basic-black: #000;
$rxc-persol--white: #fff;
$rxc-persol--white-trasparent: #ffffffcc;

// Fonts used in Persol skin

$rxc-persol-caboto-bold: "CABOTO-Bold";
$rxc-persol-caboto-regular: "CABOTO-Regular";
// Color pallette Oliver Peoples skin

$rxc-oliver--overlay: #33333399;
$rxc-oliver--warning-azure: #bce7df;
$rxc-oliver--paypalbutton-text: #ffc439;
$rxc-oliver--petrol: #202125;
$rxc-oliver--dark-blue: #00303c;
$rxc-oliver--error: #d0021b;
$rxc-oliver--success: #417505;
$rxc-oliver--mid-gray: #b6b6c4;
$rxc-oliver--paragraph-gray: #69697a;
$rxc-oliver--background-gray: #f8f8fb;
$rxc-oliver--basic-black: #000;
$rxc-oliver--white: #fff;
$rxc-oliver--white-trasparent: #ffffffcc;

// Fonts used in Oliver Peoples skin

$rxc-oliver-futura-bold: "FuturaStd-Bold";
$rxc-oliver-futura-normal: "FuturaStd-Book";
$rxc-oliver-berling-bold: "berlingltstd-bold";
$rxc-oliver-berling-bold-italic: "berlingltstd-bolditalic";
$rxc-oliver-berling-italic: "berlingltstd-italic";
$rxc-oliver-berling-roman: "berlingltstd-roman";

// Akamai
$rxc-default-imageUrl: "https://assets.lenscrafters.com/extra/image/LensCrafters/global/";
$imageUrl: "https://assets.glasses.com/extra/image/Glasses/LOGOS/rxc/";
$projectImagUrl: "https://assets.glasses.com/extra/image/Glasses/GENERAL/";

// Clearly
$rxc-clearly-font: "Mulish";

@mixin rxc-small-desktop-only {
  @media only screen and (max-width: $rxc-breakpoint-small-desktop) {
    @content;
  }
}

@mixin rxc-desktop-only {
  @media only screen and (min-width: $rxc-breakpoint-desktop) {
    @content;
  }
}

@mixin rxc-mobile-only {
  @media only screen and (max-width: $rxc-breakpoint-mobile) {
    @content;
  }
}

@mixin rxc-small-mobile-only {
  @media only screen and (max-width: $rxc-breakpoint-small) {
    @content;
  }
}

@mixin rxc-small-mobile-only {
  @media only screen and (max-width: $rxc-breakpoint-small) {
    @content;
  }
}

@mixin rxc-smartphone-only {
  @media only screen and (max-width: $rxc-breakpoint-smartphone) {
    @content;
  }
}

@mixin rxc-smaller-mobile-only {
  @media only screen and (max-width: $rxc-breakpoint-smaller) {
    @content;
  }
}

@mixin rxc-large-medium-mobile-only {
  @media only screen and (min-width: 414px) and (max-width: 425px) {
    @content;
  }
}
@mixin rxc-medium-smaller-mobile-only {
  @media only screen and (max-width: $rxc-breakpoint-medium-smaller) {
    @content;
  }
}

@mixin rxc-medium-mobile-only {
  @media only screen and (min-width: 384px) and (max-width: 425px) {
    @content;
  }
}
@mixin rxc-large-mobile-only {
  @media only screen and (min-width: 426px) and (max-width: 760px) {
    @content;
  }
}
@mixin rxc-tablet-only {
  @media only screen and (min-width: 761px) and (max-width: 850px) {
    @content;
  }
}
