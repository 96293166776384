.LensPreviewTransaction {
  &__container {
    position: relative;
  }
  /* &__white{
        &_img{
            position:absolute;
        }
    } */
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__lens {
    max-width: 680px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    position: relative;
  }

  &__frame {
    position: absolute;
    max-width: 680px;
    width: 100%;
    top: 0;
    left: 0;

    &.no-absolute {
      position: unset;
    }
  }

  &_range {
    &__container {
      flex: 0 0 auto;
      z-index: 15;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
    }
    &__image {
      &_left {
        position: relative;
        bottom: -24px;
        height: 23px;
        width: auto;
        text-transform: uppercase;
      }
      &_right {
        position: relative;
        bottom: -23px;
        width: auto;
        height: 23px;
        text-transform: uppercase;
      }
    }
  }
}

.LensPreviewTransaction_range__container .lens-slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 270px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: transparent;
  margin-bottom: -45px;

  &:focus {
    outline: none;
    &::-webkit-slider-thumb {
      filter: drop-shadow(rgb(0, 0, 0) 0px 0px 3px);
    }
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background: rgb(221, 221, 221);
    background: -webkit-linear-gradient(
      169deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 1) 5%,
      rgba(189, 189, 189, 1) 70%,
      rgba(221, 221, 221, 1) 95%,
      rgba(221, 221, 221, 0) 95%,
      rgba(221, 221, 221, 0) 100%
    );
    height: 3px;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 54px;
    height: 54px;
    background-color: transparent;
    border-radius: 50%;
    max-width: 80px;
    margin-top: 0px;
    position: relative;
    bottom: 22.5px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='1.5' transform='translate(1 1)'%3E%3Ccircle cx='12.5' cy='12.5' r='5.682' fill='%23F6F6F6' stroke='%23000'/%3E%3Cpath stroke='%231D1D1B' stroke-linecap='round' d='M8.676 3.421L7.259 0M16.106 3.421L17.523 0M21.361 8.675L24.78 7.258M21.361 16.106L24.78 17.523M16.106 21.36L17.523 24.781M8.676 21.36L7.259 24.781M3.421 16.106L0 17.523M3.421 8.675L0 7.258'/%3E%3C/g%3E%3C/svg%3E%0A")
      no-repeat;
    border-color: transparent;
    transform: rotate(20deg);
    background-position: center;
    cursor: -webkit-grab;
  }

  &::-ms-track {
    -ms-appearance: none;
    background: rgb(221, 221, 221);
    background: -ms-linear-gradient(
      169deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 1) 5%,
      rgba(189, 189, 189, 1) 70%,
      rgba(221, 221, 221, 1) 95%,
      rgba(221, 221, 221, 0) 95%,
      rgba(221, 221, 221, 0) 100%
    );
    height: 2px;
    border-radius: 5px;
    border-color: transparent;
    color: transparent;
    margin: 15px 0px 15px 0px;
  }

  &::-ms-fill-lower,
  &::-ms-fill-upper {
    background: transparent;
    border-radius: 2.6px;
  }

  &::-ms-thumb {
    -ms-appearance: none;
    width: 54px;
    height: 54px;
    background-color: transparent;
    border-radius: 50%;
    border-color: transparent;
    max-width: 80px;
    margin-top: 0px;
    position: relative;
    bottom: 22.5px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='1.5' transform='translate(1 1)'%3E%3Ccircle cx='12.5' cy='12.5' r='5.682' fill='%23F6F6F6' stroke='%23000'/%3E%3Cpath stroke='%231D1D1B' stroke-linecap='round' d='M8.676 3.421L7.259 0M16.106 3.421L17.523 0M21.361 8.675L24.78 7.258M21.361 16.106L24.78 17.523M16.106 21.36L17.523 24.781M8.676 21.36L7.259 24.781M3.421 16.106L0 17.523M3.421 8.675L0 7.258'/%3E%3C/g%3E%3C/svg%3E%0A")
      no-repeat;
    transform: rotate(20deg);
    background-position: center;
  }

  &:focus::-ms-fill-lower,
  &:focus::-ms-fill-upper {
    background: transparent;
  }

  &::-moz-range-track {
    -moz-appearance: none;
    background: rgb(221, 221, 221);
    background: -moz-linear-gradient(
      169deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 1) 5%,
      rgba(189, 189, 189, 1) 70%,
      rgba(221, 221, 221, 1) 95%,
      rgba(221, 221, 221, 0) 95%,
      rgba(221, 221, 221, 0) 100%
    );
    height: 3px;
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    width: 54px;
    height: 54px;
    background-color: transparent;
    border-radius: 50%;
    border-color: transparent;
    max-width: 80px;
    margin-top: 0px;
    position: relative;
    bottom: 22.5px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='1.5' transform='translate(1 1)'%3E%3Ccircle cx='12.5' cy='12.5' r='5.682' fill='%23F6F6F6' stroke='%23000'/%3E%3Cpath stroke='%231D1D1B' stroke-linecap='round' d='M8.676 3.421L7.259 0M16.106 3.421L17.523 0M21.361 8.675L24.78 7.258M21.361 16.106L24.78 17.523M16.106 21.36L17.523 24.781M8.676 21.36L7.259 24.781M3.421 16.106L0 17.523M3.421 8.675L0 7.258'/%3E%3C/g%3E%3C/svg%3E%0A")
      no-repeat;
    transform: rotate(20deg);
    background-position: center;
    cursor: -moz-grab;
  }

  &::-webkit-slider-thumb:active {
    cursor: -webkit-grabbing;
  }

  &::-moz-range-thumb:active {
    cursor: -moz-grabbing;
  }

  &.range {
    &.opacity1::-webkit-slider-thumb {
      width: 15px;
      height: 15px;
      margin-top: 16px;
    }

    &.opacity2::-webkit-slider-thumb {
      width: 17.5px;
      height: 17.5px;
      margin-top: 15px;
    }

    &.opacity3::-webkit-slider-thumb {
      width: 19px;
      height: 19px;
      margin-top: 14px;
    }

    &.opacity4::-webkit-slider-thumb {
      width: 21px;
      height: 21px;
      margin-top: 14px;
    }

    &.opacity5::-webkit-slider-thumb {
      width: 23px;
      height: 23px;
      margin-top: 13px;
    }

    &.opacity6::-webkit-slider-thumb {
      width: 25px;
      height: 25px;
      margin-top: 12px;
    }

    &.opacity7::-webkit-slider-thumb {
      width: 27px;
      height: 27px;
      margin-top: 10px;
    }

    &.opacity8::-webkit-slider-thumb {
      width: 29px;
      height: 29px;
      margin-top: 9.7px;
    }

    &.opacity9::-webkit-slider-thumb {
      width: 31px;
      height: 31px;
      margin-top: 8px;
    }

    &.opacity10::-webkit-slider-thumb {
      width: 34px;
      height: 34px;
      margin-top: 6.5px;
    }

    //IE
    &.opacity1::-ms-thumb {
      width: 15px;
      height: 15px;
      margin-top: 2.5px;
    }

    &.opacity2::-ms-thumb {
      width: 17.5px;
      height: 17.5px;
      margin-top: 2px;
    }

    &.opacity3::-ms-thumb {
      width: 19px;
      height: 19px;
      margin-top: 1.5px;
    }

    &.opacity4::-ms-thumb {
      width: 21px;
      height: 21px;
      margin-top: 1.5px;
    }

    &.opacity5::-ms-thumb {
      width: 23px;
      height: 23px;
      margin-top: 1px;
    }

    &.opacity6::-ms-thumb {
      width: 25px;
      height: 25px;
      margin-top: 1px;
    }

    &.opacity7::-ms-thumb {
      width: 27px;
      height: 27px;
      margin-top: 0.5px;
    }

    &.opacity8::-ms-thumb {
      width: 29px;
      height: 29px;
      margin-top: 0.5px;
    }

    &.opacity9::-ms-thumb {
      width: 31px;
      height: 31px;
      margin-top: 0.5px;
    }

    &.opacity10::-ms-thumb {
      width: 34px;
      height: 34px;
      margin-top: 0px;
    }

    //FIREFOX
    &.opacity1::-moz-range-thumb {
      width: 15px;
      height: 15px;
      margin-top: 16px;
    }

    &.opacity2::-moz-range-thumb {
      width: 17.5px;
      height: 17.5px;
      margin-top: 15px;
    }

    &.opacity3::-moz-range-thumb {
      width: 19px;
      height: 19px;
      margin-top: 14px;
    }

    &.opacity4::-moz-range-thumb {
      width: 21px;
      height: 21px;
      margin-top: 14px;
    }

    &.opacity5::-moz-range-thumb {
      width: 23px;
      height: 23px;
      margin-top: 13px;
    }

    &.opacity6::-moz-range-thumb {
      width: 25px;
      height: 25px;
      margin-top: 12px;
    }

    &.opacity7::-moz-range-thumb {
      width: 27px;
      height: 27px;
      margin-top: 10px;
    }

    &.opacity8::-moz-range-thumb {
      width: 29px;
      height: 29px;
      margin-top: 9.7px;
    }

    &.opacity9::-moz-range-thumb {
      width: 31px;
      height: 31px;
      margin-top: 8px;
    }

    &.opacity10::-moz-range-thumb {
      width: 34px;
      height: 34px;
      margin-top: 6.5px;
    }
  }
}
