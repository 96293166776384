@import "./fonts/fonts.scss";
@import "./mixins/global.scss";

dialog.login {
  z-index: 1000000;
  border: 0;
  border-radius: 10px;

  button {
    display: inline-block;
    width: 100px;
    text-align: center;
    margin: 10px 10px 0;
    cursor: pointer;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.25);
  }

  input#right_eye_axis {
    box-sizing: border-box;
    background-color: aqua;
  }
}
