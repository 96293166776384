@import "../../mixins/global";

.StepCard {
  &__container {
    display: block;
    box-sizing: border-box;

    &_badge {
      position: absolute;
      top: 20px;
      right: 32px;
    }
  }

  &__card_disabled {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 0;
    margin-bottom: 24px;
    overflow: visible;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
  }

  &__card {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    border-radius: 8px;
    border: none;
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 24px;
    overflow: visible;
    cursor: pointer;
    text-align: left;

    &:focus {
      background-color: white;
      box-shadow: 0 0 17px 0 rgb(0 0 0 / 12%);
      outline: none;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: -3px;
        left: -3px;
        width: 100%;
        height: 100%;
        border: 3.5px solid #326fa8;
        border-radius: 12px;
        pointer-events: none;
        box-sizing: content-box;
      }
    }

    &:hover {
      background-color: white;
      box-shadow: 0 0 17px 0 rgb(0 0 0 / 12%);
      outline: none;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border: 2px solid $rxc-grey2-sun-default;
        border-radius: 8px;
        pointer-events: none;
        box-sizing: content-box;
      }
    }
    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      border: 1px solid #b8b8b8;
      border-radius: 8px;
      pointer-events: none;
      box-sizing: content-box;
    }
    @include rxc-mobile-only {
      margin-bottom: 16px;
    }
  }

  &__layout {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    flex-direction: column;

    &__disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #f2f4f5;
      opacity: 40%;
      z-index: 10;
      border-radius: 8px;
    }

    &__imgcontainer {
      max-width: 64px;
      height: auto;
      margin-top: 17.3px;
      margin-left: 16px;
      margin-right: 16px;

      @include rxc-mobile-only {
        display: none;
      }

      &.noImage {
        padding: 0 0 0 18px;
        flex: 0;
        display: none;
      }

      &__img {
        max-width: 32px;
      }
    }
    &__largeimgcontainer {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 150px;
      max-width: 130px;
      max-height: 112px;
      &.noImage {
        padding: 0 0 0 18px;
        flex: 0;
      }

      &__largeimg {
        align-self: center;
        max-width: 100%;
        width: fit-content;

        @include rxc-mobile-only {
          max-width: 100px;
        }
      }

      @include rxc-mobile-only {
        max-width: 100px;
      }
    }
    &__topBadgeWrapper {
      position: absolute;
      right: -2px;
      top: 16px;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
      width: 100%;
      justify-content: center;
      &.spacer {
        padding-left: 16px;
      }
      @include rxc-desktop-only {
        padding: 16px;
      }

      @include rxc-mobile-only {
        padding: 16px;
      }

      &__title {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        @include rxc-mobile-only {
        }
        &__badgespacer {
          font: 16px $rxc-sun-default-sofiaBold-font;
          color: $rxc-grey2-sun-default;
          margin-bottom: 8px;

          @include rxc-mobile-only {
            margin-top: 24px;
            font: 16px $rxc-sun-default-sofiaBold-font;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 0px;
            color: $rxc-grey2-sun-default;
          }
        }
      }

      &__description {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        flex-wrap: wrap;
        & p {
          margin: 8px 0px 16px 0px;
        }
        & ul {
          list-style: none;
          padding: 0px;
          & li {
            margin: 0px 16px 8px 0px;
            display: inline-block;
            @include rxc-mobile-only {
              display: block;
            }
            &::before {
              content: "";
              background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%23222222'/%3e%3cpath d='M3.33332 4.05699L1.47132 2.19532L0.528656 3.13799L3.33332 5.94299L7.80466 1.47132L6.86199 0.528656L3.33332 4.05699Z' fill='%23222222' transform='translate(3.5%2c4.8)'/%3e%3c/svg%3e")
                no-repeat center center/cover;
              // position: absolute;
              width: 16px;
              height: 16px;
              z-index: 100;
              display: inline-block;
              margin: 0px 9px -3px 0px;
            }
            &::after {
              content: "|";
              margin-left: 16px;
              width: 5px;
              height: 16px;
              display: inline-block;
              color: #c0c1c6;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
        @include rxc-mobile-only {
        }
      }

      &__spacer {
        height: 1px;
        background-color: #b8b8b8;
      }
      &__spacerMobile {
        height: 1px;
        background-color: #b8b8b8;
        margin-bottom: 8px;
        width: 100%;
      }

      &__learnMorePriceContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.onlyPrice {
          justify-content: flex-end;
        }
      }

      &__price {
        position: relative;
        align-self: flex-end;
        margin-bottom: 0px;
        font-family: $rxc-sun-default-sofia-font;
        font-size: 16px;
        font-weight: 500;
        color: #222 !important;
      }

      &__learnMore {
        box-sizing: border-box;
        align-self: flex-start;
        display: flex;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        text-decoration: underline;
        color: #1c4da1;
        & p {
          margin: 16px 0px 0px;
          @include rxc-mobile-only {
            color: #222222;
            font-size: 0px;
            width: 21.5px;
            height: 21.5px;
            content: url("data:image/svg+xml, %3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 1.75C6.39137 1.75 2.25 5.89137 2.25 11C2.25 16.1086 6.39137 20.25 11.5 20.25C16.6086 20.25 20.75 16.1086 20.75 11C20.75 5.89137 16.6086 1.75 11.5 1.75ZM0.75 11C0.75 5.06294 5.56294 0.25 11.5 0.25C17.4371 0.25 22.25 5.06294 22.25 11C22.25 16.9371 17.4371 21.75 11.5 21.75C5.56294 21.75 0.75 16.9371 0.75 11ZM11.5 10.25C11.9142 10.25 12.25 10.5858 12.25 11V15C12.25 15.4142 11.9142 15.75 11.5 15.75C11.0858 15.75 10.75 15.4142 10.75 15V11C10.75 10.5858 11.0858 10.25 11.5 10.25ZM11.5 6.25C11.0858 6.25 10.75 6.58579 10.75 7C10.75 7.41421 11.0858 7.75 11.5 7.75H11.51C11.9242 7.75 12.26 7.41421 12.26 7C12.26 6.58579 11.9242 6.25 11.51 6.25H11.5Z' fill='%23222222'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            margin: 16px 0px 0px;
          }
        }
      }
    }
  }
}

.Step-Brand .StepCard__layout__imgcontainer__img {
  max-width: 64px;
}

.Step-Treatments .StepCard__layout__imgcontainer__img {
  max-width: 64px;
}
