@import "../../mixins/global";

.margin {
  margin: 0 10px !important;
}

.PupillaryDistance {
  &__titleContainer {
    font-family: "Mulish";
    font-style: normal;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0;
    }

    &__link {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin: 0px 0 0 10px;
      cursor: pointer;
      text-decoration: underline;
      max-width: fit-content;
      color: #1c4da1;
    }
  }
  &__subtitleContainer {
    margin-bottom: 16px;
    font-family: SofiaPro;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    text-align: left;
    color: #222;
    & a {
      color: #557b92;
      text-decoration: underline;
    }
  }
  &__checkboxContainer {
    &__noMargin {
      display: flex;
      align-items: center;
      margin-top: 2.5px;
      margin-left: 0px !important;
      @include rxc-mobile-only {
        margin-top: 16px !important;
      }
    }
    &__margin {
      display: flex;
      align-items: center;
      margin-left: 20px !important;
      margin-top: 2.5px;
    }
  }

  &__checkBoxText {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
  }

  &__optionsContainer {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    &__select_container {
      position: relative;
      @include rxc-small-mobile-only {
        width: calc(100vw / 4) !important;
      }
      &.margin {
        margin-right: 16px !important;
        margin-left: 8px !important;
      }
      &.marginRight {
        margin-right: 23px !important;
        margin-left: 8px !important;
      }
      & select {
        cursor: pointer;
        display: flex;
        justify-content: start;
        width: 107px !important;
        padding-left: 10px;
        height: 30px;
        background-color: #ffffff;
        border: solid 1px #6f6e6f;
        color: #333333;
        border-radius: 4px;
        align-items: center;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16px;
        -webkit-appearance: none;
        @include rxc-small-mobile-only {
          width: 100% !important;
        }
        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #767676;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #767676;
        }
      }
      & select.__DoubleSelect {
        height: 32px;
        padding: 8px 16px;
        gap: 42px;
        .error {
          border-color: #e32118;
        }
        @include rxc-small-mobile-only {
          width: 100% !important;
        }
      }
      &::after {
        content: "";
        width: 12px;
        height: 10px;
        position: absolute;
        top: 12px;
        right: 8px;
        background-size: contain;
        background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        pointer-events: none;
      }
    }
  }

  &__checkboxLabel {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0 !important;
  }

  &__pdValueNotSelectedWarning {
    color: #e32118;
    font-family: SofiaPro;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.16px;
    margin-top: 16px;
    pointer-events: none;

    &::after {
      content: attr(data-content);
      color: #244c5a;
      text-decoration: underline;
      pointer-events: all;
    }
  }
}
