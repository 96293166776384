@import "../../../mixins/global.scss";

.LensColor {
  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: $rxc-grey2-sun-default;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-sun-default-sofiaBold-font;
      left: 0px;
    }

    width: fit-content;
    height: 40px;
    width: 110px;
    margin: 0;
    padding: 10px 0;
    border-radius: 4px;
    background-color: $rxc-grey2-sun-default;
    border: none;
    position: relative;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    cursor: pointer;

    &_container {
      display: flex;
      justify-content: center;
      align-items: center;

      @include rxc-mobile-only {
        bottom: 22%;
        left: 50%;
        position: relative;
        margin-top: 11.5px;
        transform: translate(-50%, 0);
      }
      @include rxc-desktop-only {
        position: absolute;
        right: 3%;
        bottom: 12% !important;
        transform: translateX(-50%);
      }
      &.borderOutline {
        @include rxc-mobile-only {
          padding: 2px;
          padding-bottom: 20px;
          border: 1px solid $rxc-fontColor-costa;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 0px;
          margin-top: 16px;
          margin-bottom: 50px;
        }
      }
      &__detailBox {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        margin: 16px 20px 8px 20px;
        word-break: break-word;
        align-self: flex-start;
        @include rxc-desktop-only {
          position: absolute;
          bottom: 80%;
          right: -3%;
          width: 280px;
        }
        @include rxc-mobile-only {
          margin: 16px 0 8px 0;
        }

        &__content {
          display: flex;
          flex-direction: column;
          position: relative;
          @include rxc-desktop-only {
            &::before {
              content: "";
              position: absolute;
              top: 6px;
              left: -14.5px;
              width: 1px;
              height: 100%;
              background-color: #222;
            }
            &::after {
              content: "";
              position: absolute;
              left: -19px;
              bottom: -7px;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              background-color: #222;
            }
          }
          &__textContainer {
            display: flex;
            line-height: 24px;
            margin-bottom: 4px;
            font-size: 14px;
            &__text {
              margin-right: 8px;
            }
            &__inner {
              font-family: $rxc-default-sofiaBold-font;
              @include rxc-desktop-only {
                max-width: 60%;
              }
              @include rxc-small-mobile-only {
                max-width: 95%;
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  }

  &__filtersContainer {
    display: flex;
    margin-top: 16px;
    margin-bottom: 10.5px;
    overflow-x: auto;
    scroll-behavior: smooth;
    vertical-align: baseline;
    @include rxc-mobile-only {
      margin-bottom: 18px;
      margin-top: 0px;
    }
    &__fade {
      content: "";
      display: flex;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      width: 60px;
      right: 7%;
      margin-top: 20px;
      position: relative;
      @include rxc-mobile-only {
        margin-top: 16px;
      }
    }

    &__scrollButton {
      width: 25px;
      display: flex;
      background-color: $rxc-grey2-sun-default;
      height: 35px;
      margin-top: 34px;
      border: none;
      @include rxc-mobile-only {
        margin-top: 18px;
        margin-bottom: 18px;
      }
    }
    &__separatorvertical {
      width: 2px;
      background-color: $rxc-grey-separator;
      height: 44px;
      margin-top: 29.5px;
      margin-right: 2px;
      @include rxc-mobile-only {
        margin-top: 15px;
      }
    }
  }

  &__filterPill {
    background-color: $rxc-white-color-1;
    max-height: fit-content;
    cursor: pointer;
    max-height: fit-content;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    border: solid 1px $rxc-grey-separator;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;

    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      color: $rxc-white-color-1;
      background-color: $rxc-grey2-sun-default;
      border-radius: 100px;
      border: solid 1px $rxc-grey2-sun-default;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }

  &__container {
    &_separator {
      display: block;
      margin-top: 19.5px;
      width: 100%;
      height: 1px;
      background-color: $rxc-grey-separator;
      @include rxc-mobile-only {
        margin-top: 0px;
      }
    }
    &__color_category_description {
      font-family: $rxc-sun-default-sofia-font;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-top: 15px;
    }
  }

  &__groupsContainer {
    display: flex;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @include rxc-desktop-only {
      flex-flow: row wrap;
      row-gap: 8.5px;
      column-gap: 32px;
      padding-bottom: 75px;
      margin-right: -16px;
      margin-top: 15px;
    }
    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 0px;
      margin-top: 27px;
    }
    &__less_space {
      @include rxc-desktop-only {
        margin-top: 5px;
      }
      @include rxc-mobile-only {
        margin-top: 15px;
      }
      @include rxc-small-mobile-only {
        margin-top: 10px;
      }
    }
  }
  &__alreadyIncluded {
    &__container {
      &__list {
        list-style-type: none;
        margin: 0;
        padding-inline-start: 0;
        & li {
          font-family: $rxc-sgh-helvetica-regular;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #555555;
          margin-bottom: 10px;
          @include rxc-small-mobile-only {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      &__info--icon {
        margin-left: 10px;
        cursor: pointer;
        height: 14.33px;
        width: 14.33px;
        background-repeat: no-repeat;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk5OTkyIDEuODMzMzFDNC41OTQxNiAxLjgzMzMxIDEuODMzMjUgNC41OTQyMiAxLjgzMzI1IDcuOTk5OThDMS44MzMyNSAxMS40MDU3IDQuNTk0MTYgMTQuMTY2NiA3Ljk5OTkyIDE0LjE2NjZDMTEuNDA1NyAxNC4xNjY2IDE0LjE2NjYgMTEuNDA1NyAxNC4xNjY2IDcuOTk5OThDMTQuMTY2NiA0LjU5NDIyIDExLjQwNTcgMS44MzMzMSA3Ljk5OTkyIDEuODMzMzFaTTAuODMzMjUyIDcuOTk5OThDMC44MzMyNTIgNC4wNDE5NCA0LjA0MTg4IDAuODMzMzEzIDcuOTk5OTIgMC44MzMzMTNDMTEuOTU4IDAuODMzMzEzIDE1LjE2NjYgNC4wNDE5NCAxNS4xNjY2IDcuOTk5OThDMTUuMTY2NiAxMS45NTggMTEuOTU4IDE1LjE2NjYgNy45OTk5MiAxNS4xNjY2QzQuMDQxODggMTUuMTY2NiAwLjgzMzI1MiAxMS45NTggMC44MzMyNTIgNy45OTk5OFpNNy45OTk5MiA3LjQ5OTk4QzguMjc2MDYgNy40OTk5OCA4LjQ5OTkyIDcuNzIzODQgOC40OTk5MiA3Ljk5OTk4VjEwLjY2NjZDOC40OTk5MiAxMC45NDI4IDguMjc2MDYgMTEuMTY2NiA3Ljk5OTkyIDExLjE2NjZDNy43MjM3OCAxMS4xNjY2IDcuNDk5OTIgMTAuOTQyOCA3LjQ5OTkyIDEwLjY2NjZWNy45OTk5OEM3LjQ5OTkyIDcuNzIzODQgNy43MjM3OCA3LjQ5OTk4IDcuOTk5OTIgNy40OTk5OFpNNy45OTk5MiA0LjgzMzMxQzcuNzIzNzggNC44MzMzMSA3LjQ5OTkyIDUuMDU3MTcgNy40OTk5MiA1LjMzMzMxQzcuNDk5OTIgNS42MDk0NiA3LjcyMzc4IDUuODMzMzEgNy45OTk5MiA1LjgzMzMxSDguMDA2NTlDOC4yODI3MyA1LjgzMzMxIDguNTA2NTkgNS42MDk0NiA4LjUwNjU5IDUuMzMzMzFDOC41MDY1OSA1LjA1NzE3IDguMjgyNzMgNC44MzMzMSA4LjAwNjU5IDQuODMzMzFINy45OTk5MloiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==");
      }
      &__toolptip {
        font-family: $rxc-sgh-helvetica-regular;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16px;
      }
    }
  }
  //scrollbar mobile removed
  &__groupsContainer::-webkit-scrollbar {
    width: 0 !important;
    opacity: 0 !important;
    display: none !important;
  }

  &__polarized {
    &_container {
      display: flex;
      flex-direction: row;
      margin-top: 18px;
      position: relative;

      @include rxc-mobile-only {
        margin-top: 0px;
        align-items: center;
      }

      &_titleContainer {
        font-family: $rxc-sun-default-sofia-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: #000000;
        order: 2;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        padding: 5px -4px 0px 8px;
        @include rxc-mobile-only {
          margin-top: 2px;
        }
      }

      &__moreinfo {
        color: $rxc-grey2-sun-default;
        flex-flow: row;
        justify-content: center;
        order: 3;
        font-size: 0px;
        width: 15.3px;
        height: 15.3px;
        margin-left: 8.8px;
        background-repeat: no-repeat;
        cursor: pointer;
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTggMS44MzNDNC41OTYgMS44MzMgMS44MzUgNC41OTQgMS44MzUgOGMwIDMuNDA2IDIuNzYgNi4xNjcgNi4xNjcgNi4xNjcgMy40MDUgMCA2LjE2Ni0yLjc2MSA2LjE2Ni02LjE2NyAwLTMuNDA2LTIuNzYtNi4xNjctNi4xNjYtNi4xNjd6TS44MzUgOEMuODM0IDQuMDQyIDQuMDQzLjgzMyA4LjAwMS44MzNjMy45NTggMCA3LjE2NiAzLjIwOSA3LjE2NiA3LjE2NyAwIDMuOTU4LTMuMjA4IDcuMTY3LTcuMTY2IDcuMTY3Uy44MzQgMTEuOTU4LjgzNCA4em03LjE2Ny0uNWMuMjc2IDAgLjUuMjI0LjUuNXYyLjY2N2MwIC4yNzYtLjIyNC41LS41LjVzLS41LS4yMjQtLjUtLjVWOGMwLS4yNzYuMjI0LS41LjUtLjV6bTAtMi42NjdjLS4yNzYgMC0uNS4yMjQtLjUuNXMuMjI0LjUuNS41aC4wMDZjLjI3NiAwIC41LS4yMjQuNS0uNXMtLjIyNC0uNS0uNS0uNWgtLjAwNnoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K");

        &__tooltip {
          font-family: $rxc-sun-default-sofia-font;
          font-size: $rxc-sun-default-12-fontSize;
          z-index: 1;
          width: fit-content;
          justify-content: center;
          max-width: 289px;
          padding: 23px 26px 23px 31px;
          background-color: $rxc-white-color-1;
          -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          @include rxc-small-mobile-only {
            width: 210px;
          }
        }
      }

      &_switch {
        order: 1;
        flex-flow: row wrap;
        justify-content: flex-start;
        position: left;
        border-radius: 12px;
        .__react_component_tooltip {
          visibility: visible !important;
        }
        @include rxc-mobile-only {
          margin-top: 20px;
        }
      }

      &_promoTitle {
        font-family: $rxc-sun-default-sofia-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-red-color-2;
        text-transform: uppercase;
        margin: 0 0 0 7px;

        @include rxc-mobile-only {
          margin: 0 0 0 8px;
        }
      }

      & p {
        margin: 0;
        font-family: $rxc-sun-default-sofia-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-grey2-sun-default;
        line-height: 14px;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }
  }
  &__detailsContainer_mobile {
    width: 90%;
    @include rxc-desktop-only {
      display: none;
    }
  }
  &__detailsContainer_desktop {
    @include rxc-mobile-only {
      display: none;
    }
  }
}
