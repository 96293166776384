@import "../../mixins/global";

.FullPage_root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100001;
  display: flex;
  flex-flow: column nowrap;
  width: inherit;
  height: 100%;
  background-color: white;
  right: 0;

  .ErrorBundary {
    &__container {
      position: absolute;
      height: 100vh;
      width: 100vw;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2147483649;

      .ErrorModal {
        &__container {
          position: relative;
          height: 300px;
          width: 500px;
          background: #ffffff;
          box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
            0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
          border-radius: 8px;
          padding: 40px, 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &__title {
            position: relative;
            width: 80%;
            font-family: "MinervaModern";
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.005em;
            color: #222222;
          }

          &__description {
            position: relative;
            width: 90%;
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.5px;
            color: #222222;
          }

          &__button {
            position: relative;
            width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background-color: #557b92;
            color: white;
            border-radius: 100px;
            text-transform: uppercase;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  & * {
    box-sizing: border-box;
  }
}

:global #rxcApp {
  /** {
        box-sizing: border-box;
        font: 15px "sofia-pro-light", Verdana, Arial, sans-serif;
    }*/
  .cursor-hand {
    cursor: pointer;
  }
  .arrow {
    border: solid $rxc-black-color-1;
    border-width: 0.1px;
    border-width: 0 1px 1px 0;
  }

  .arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .lc-control {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: 300;

    &:hover {
      input {
        &:not([disabled]) {
          & ~ .lc-control__indicator {
            border-color: $rxc-grey-color-7;
          }
        }
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:focus {
        & ~ .lc-control__indicator {
          border-color: $rxc-grey-color-7;
        }
      }

      &:checked {
        & ~ .lc-control__indicator {
          background: $rxc-white-color-1;

          &:after {
            display: block;
          }
        }
      }

      &:disabled {
        & ~ .lc-control__indicator {
          background: #e6e6e6;
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }

    .lc-control__indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 15px;
      width: 15px;
      background: $rxc-white-color-1;
      border: 1px solid $rxc-black-color-1;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &.lc-control--checkbox {
      .lc-control__indicator {
        &:after {
          left: 4px;
          top: 0px;
          width: 4px;
          height: 10px;
          border: solid $rxc-blue-color-1;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        input {
          &:disabled {
            & ~ .lc-control__indicator {
              &:after {
                border-color: #7b7b7b;
              }
            }
          }
        }
      }
    }

    &.lc-control--radio {
      .lc-control__indicator {
        border-radius: 50%;

        &:after {
          left: 4px;
          top: 4px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background: $rxc-blue-color-1;
        }
      }
    }
  }

  .SwitchControl_container {
    //margin-top: 20px;
    @include rxc-mobile-only {
      min-height: 20px;
      margin-bottom: 20px;
    }

    .SwitchControl_label {
      position: relative;
      display: inline-block;
      width: 44px;
      height: 24px;
      margin-right: 10px;
      @include rxc-mobile-only {
        float: left;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #1c4da1;
        }

        &:checked + .slider::before {
          //-webkit-transform: translateX(18px);
          //-ms-transform: translateX(18px);
          //transform: translateX(18px);
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
          @include rxc-desktop-only {
            left: 4px;
          }
        }

        &:focus + .slider {
          box-shadow: 0px 0px 0px 3px #00000045;
          transition: 0s;
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d8d8d8;
        background-color: transparent;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
        background-color: #6f6e6f;
        height: 100% !important;
        @include rxc-mobile-only {
          width: 42px !important;
        }

        &::before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 2px;
          top: 2px;
          bottom: 2px;
          background-color: $rxc-white-color-1;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }

    .two-pd-label {
      position: absolute;
      @include rxc-mobile-only {
        position: relative;
        float: left;
      }
    }
  }

  .border-button {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    font: 12px "sofia-pro", Verdana, Arial, sans-serif;
    border-radius: 12px;
    background-color: transparent;
    color: #326fa8;
    border: 1px solid #326fa8;
  }
}
.RXC {
  &__is-locked {
    @include rxc-mobile-only {
      height: calc(100vh - 1px) !important;
      box-sizing: border-box !important;
      overflow: hidden !important;
    }
  }
}
