@import "../../mixins/global.scss";

.PrescriptionFromMyAccountCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 8px;
  @include rxc-mobile-only {
    flex-direction: column;
    align-items: flex-start;
  }
  &__infoContainer {
    &__name {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0px 0px 8px 0px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__date {
      margin: 0px;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 8px;
    background: #000000;
    border-radius: 4px;
    color: white;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 100%;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
