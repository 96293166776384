@import "../../mixins/global";
.StepBadge {
  &__container {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    top: 18px;
    right: 32px;
    @include rxc-mobile-only {
      top: 12px;
      left: 16px;
    }
  }

  &__badge {
    &:last-child {
      margin-right: 0;
    }

    &_mostPopular {
      display: flex;
      font: 14px $rxc-sun-default-sofia-font;
      overflow: hidden;
      z-index: 1;
      color: #192c50;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2px;
      @include rxc-mobile-only {
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }
    &_recommended {
      display: flex;
      font: 14px $rxc-sun-default-sofia-font;
      overflow: hidden;
      z-index: 1;
      color: #192c50;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2px;
      @include rxc-mobile-only {
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }
    &_bestPrescription {
      display: flex;
      font: 14px $rxc-sun-default-sofia-font;
      overflow: hidden;
      z-index: 1;
      color: #192c50;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2px;
      @include rxc-mobile-only {
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }

    &_comingSoon {
      display: flex;
      font: 14px $rxc-sun-default-sofia-font;
      overflow: hidden;
      z-index: 1;
      color: #192c50;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2px;
      @include rxc-mobile-only {
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }
  }
}
