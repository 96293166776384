@import "../../mixins/global";

.PrescriptionModal {
  &__desktop {
    @include rxc-mobile-only {
      display: none;
    }
  }

  &__mobile {
    display: none;
    @include rxc-mobile-only {
      display: block;
    }
  }

  &__overlay {
    position: absolute;
    background-color: rgba(51, 51, 51, 0.6);
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__showPDModalBody {
    padding: 0 64px 53px 64px;

    @include rxc-mobile-only {
      max-height: 60vh;
      overflow-y: scroll;
      padding: 0 30px 10px;
      margin-bottom: 10px;
    }

    & li {
      font: 14px "sofia-pro", Verdana, Arial, sans-serif;
      line-height: 24px;
      list-style: unset !important;
    }

    & ul {
      margin: 0;
      padding-left: 25px;
      list-style: unset !important;
    }
  }

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 60vw;
    width: 864px;
    max-height: fit-content;
    border-radius: 8px;
    background-color: white;
    z-index: 101;
    display: block;

    @include rxc-mobile-only {
      max-width: 90vw;
      height: fit-content;
      min-height: 568px;
    }

    &__closeContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 21px;
      margin-right: 21px;

      @include rxc-mobile-only {
        margin: 0px;
        position: absolute;
        top: 3%;
        right: 5%;
      }
      & span {
        display: none;
      }
    }

    &__close {
      width: fit-content;
      display: block;
      content: "";
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.7992 1.13328L14.8659 0.199951L7.99922 7.06662L1.13255 0.199951L0.199219 1.13328L7.06589 7.99995L0.199219 14.8666L1.13255 15.8L7.99922 8.93328L14.8659 15.8L15.7992 14.8666L8.93255 7.99995L15.7992 1.13328Z' fill='black'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      color: transparent;
      min-width: 15px;
      height: 15px;
      background-size: 100%;
      align-self: center;
      cursor: pointer;
    }

    &__title {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 21px;

      @include rxc-mobile-only {
        padding: 32px 20px 0px;
      }

      @include rxc-desktop-only {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &__video {
      width: fit-content;
      max-height: 22vh;
      margin: 0 auto;
      margin-bottom: 40px;
      justify-content: center;
      align-items: center;
      display: flex;

      &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
      }

      &__playButton {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='70px' height='70px' viewBox='0 0 484.566 484.566' style='enable-background:new 0 0 484.566 484.566;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill='white' d='M360.342,216.266L219.373,113.882c-9.783-7.106-22.723-8.121-33.498-2.63c-10.771,5.49-17.556,16.559-17.556,28.65V344.67 c0,12.092,6.784,23.158,17.556,28.646c4.61,2.348,9.611,3.506,14.6,3.506c6.666,0,13.301-2.07,18.898-6.138l140.969-102.383 c8.33-6.047,13.256-15.719,13.256-26.018C373.598,231.988,368.672,222.312,360.342,216.266z'/%3E%3Cpath fill='white' d='M242.285,0C108.688,0,0.004,108.689,0.004,242.283c0,133.592,108.686,242.283,242.281,242.283 c133.594,0,242.278-108.691,242.278-242.283C484.562,108.689,375.881,0,242.285,0z M242.285,425.027 c-100.764,0-182.744-81.979-182.744-182.744c0-100.766,81.98-182.742,182.744-182.742s182.745,81.976,182.745,182.742 C425.029,343.049,343.049,425.027,242.285,425.027z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
        cursor: pointer;
        width: 70px;
        height: 70px;

        &.invisible {
          display: none;
        }
      }

      & video {
        max-height: 22vh;
        height: auto;

        @include rxc-mobile-only {
          width: 90%;
        }
      }
    }

    &__infoModalBody {
      //width: 50vw;
      //max-width: 1000px;
      @include rxc-desktop-only {
        padding: 0 64px 40px;
        column-count: 1;
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-gap: 64px;
      }

      @include rxc-mobile-only {
        padding: 0 20px 0 20px;
        overflow-y: scroll;
        height: 180px;
        margin-bottom: 10px;
      }
    }

    &__infoModalBodyElem {
      display: inline-block;
      margin-bottom: 20px;
      font-family: "Mulish";
      font-style: normal;
      line-height: 24px;
      @include rxc-desktop-only {
        max-width: 50vw;
      }

      &__title {
        display: inline;
        font-weight: 700;
        font-size: 16px;
        margin-right: 4px;
        text-transform: capitalize;
      }

      &__desc {
        display: inline;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__bodyText {
      font: 14px "sofia-pro", Verdana, Arial, sans-serif;
      margin-bottom: 20px;
      line-height: 1.71;
      text-align: center;
    }

    &__toggle {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      border: solid 1px #557b92;
      width: 240px;
      height: 32px;
      font-family: SofiaPro;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.5px;
      color: #557b92;
      margin: 0 auto;
      & div {
        position: relative;
        height: 101%;
        // width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1px;
        border-radius: 100px;
      }
      &__active {
        background-color: #557b92;
        color: white;
        width: 55%;
      }
    }

    &__bodyListTitle {
      font: 14px "sofia-pro-bold", Verdana, Arial, sans-serif;
      line-height: 24px;
    }
  }
}
