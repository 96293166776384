@import "../../mixins/global";

.StepInReview {
  display: block;

  &__mainContainer {
    display: flex;
    padding-top: 16px;
    width: 100%;

    &__contentContainer {
      display: flex;
      flex-direction: column;
      word-break: break-word;
      width: 100%;

      &__titleWrapper {
        display: flex;
        justify-content: space-between;
      }

      &__title_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        &__title {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.5px;
          line-height: 24px !important;
          text-align: left;
          color: #222222;

          @include rxc-desktop-only {
            padding-right: 0.5rem;
            font: 14px "sofia-pro", Verdana, Arial, sans-serif;
            white-space: nowrap;
          }

          @include rxc-mobile-only {
            font: 14px "sofia-pro", Verdana, Arial, sans-serif;
            padding-right: 0.15rem;
          }
          @include rxc-smaller-mobile-only {
            font: 12px "sofia-pro", Verdana, Arial, sans-serif;
          }
        }
        &__editContainer {
          @include rxc-mobile-only {
            padding-left: 10px;
          }
          @include rxc-smaller-mobile-only {
            padding-left: 4px;
          }

          &__editBtn {
            cursor: pointer;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            color: #1c4da1;
            text-align: left;
            text-decoration: underline;

            &body {
              font-family: "sofia-pro-semibold" !important;
              font-size: 12px !important;
              font-weight: 600 !important;
            }

            &:first-child {
              text-transform: capitalize;
            }
          }
        }
      }

      &__selectionContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .StepPrice {
          white-space: nowrap;
        }
      }

      &__selection {
        font-family: "sofia-pro-bold";
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 4px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #222222;

        &.seeDetails {
          align-items: center;
          display: flex;
        }

        @include rxc-desktop-only {
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }

        &__seeDetails {
          cursor: pointer;
          font-size: 12px;
          margin-left: 8px;
          text-decoration: underline;
        }
      }
    }

    &__priceContainer {
      justify-content: flex-end;
      display: flex;
      width: fit-content;

      .StepPrice__container__inner__strikePrice {
        font-family: "sofia-pro";
        font-size: 12px;
        line-height: 1.14;
        @include rxc-smaller-mobile-only {
          font-size: 10px;
        }
      }
      .StepPrice__container__inner__price {
        font-family: "sofia-pro";
        font-size: 16px;
        line-height: 24px;

        @include rxc-smaller-mobile-only {
          font-size: 14px;
        }
      }
    }

    &__includedContainer {
      text-align: right;
      &__label {
        font-size: 16px;
        font-family: "sofia-pro";
        letter-spacing: 0.5px;
      }
    }
  }
}
