@import "../../mixins/global";

.ExitModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 472px;
      height: auto;
      padding: 40px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;

      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }
    &__title {
      color: #1f1f24;
      margin-bottom: 4px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      font: 14px "sofia-pro-bold", Verdana, Arial, sans-serif;
    }
    &__subtitle {
      text-align: center;
      line-height: 1.33;
      letter-spacing: 0.16px;
      color: #222222;
      font: 12px "sofia-pro", Verdana, Arial, sans-serif;
    }
    &__buttonsContainer {
      margin-top: 20px;
      width: 100%;
      height: 32px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        width: 100%;
      }

      &__buttonExit,
      &__buttonContinue {
        letter-spacing: 0.5px;
        font-weight: 600;
        font: 16px "sofia-pro", Verdana, Arial, sans-serif;
        padding: 4px 16px;

        @include rxc-mobile-only {
          font: 12px "sofia-pro", Verdana, Arial, sans-serif;
          margin: 10px;
        }
      }

      &__buttonExit {
        background-color: #557b92;
        border: none;
        border-radius: 100px;
        width: 100%;
        height: 40px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 40px;

        @include rxc-mobile-only {
          font-weight: 500;
          min-height: 48px;
          height: fit-content;
          max-width: 180px;
        }
        &:hover {
          background-color: $rxc-blue-color-2 !important;
          box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
            0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
        }
      }
      &__buttonContinue {
        border: solid 1px #557b92;
        background-color: #fff;
        color: #557b92;
        border-radius: 100px;
        background-color: #557b92;
        background-color: #fff;
        width: 100%;
        height: 40px;
        color: #557b92;
        text-transform: uppercase;
        cursor: pointer;

        @include rxc-mobile-only {
          font-weight: 500;
          min-height: 48px;
          height: fit-content;
          max-width: 180px;
        }
        &:hover {
          box-shadow: 0 0 0 1px $rxc-blue-skin-default;
          background-color: rgba(85, 123, 146, 0.05) !important;
        }
      }
    }

    &__save {
      font-size: 16px;
      font-weight: 600;
      color: #557b92;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 24px;
      font: 16px "sofia-pro-bold", Verdana, Arial, sans-serif;
    }
  }
}
