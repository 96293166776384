@import "../../mixins/global";

.LearnMoreModal__container .carousel-status {
  display: none;
}

.LearnMoreModal__container .control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LearnMoreModal__container .card-gone {
  display: none;
}

.LearnMoreModal__container video {
  border-radius: 8px;
}

.LearnMoreModal__container .dot {
  border: solid 0.5px #767676;
  box-shadow: none !important;
  margin: 0 2px !important;
}

.LearnMoreModal__container .dot.selected {
  background-color: #222 !important;
  box-shadow: none !important;
}

.LearnMoreModal__container .carousel.carousel-slider {
  @include rxc-mobile-only {
    width: 100% !important;
  }
}

.LearnMoreModal__container .slide {
  @include rxc-mobile-only {
    min-width: 80% !important;
  }
}

.LearnMoreModal__container .slider {
  background-color: transparent !important;
}

.LearnMoreModal__container__carouselMarginfirst .slider-wrapper {
  @media only screen and (min-width: 1200px) {
    transform: translateX(15%) !important;
    transition: 0.3s !important;
  }
}

.LearnMoreModal__container__carouselMarginlast .slider-wrapper {
  @media only screen and (min-width: 1200px) {
    transform: translateX(-15%) !important;
    transition: 0.3s !important;
  }
}

.LearnMoreModal__container .tappable {
  cursor: url(https://assets.lenscrafters.com/extra/image/rxc/images/tap_oval.png)
      57 57,
    auto;
}

.LearnMoreModal__container .slide.selected {
  @include rxc-mobile-only {
    min-width: 80% !important;
  }
}

.LearnMoreModal__container .card-invisible {
  display: none;
}

.CardInfoPanel {
  &__container {
    position: absolute;
    height: auto !important;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 0 0 rgba(8, 27, 61, 0.4);
    background-image: radial-gradient(
      circle at 0 0,
      rgba(255, 255, 255, 0.29),
      rgba(255, 255, 255, 0.28)
    );

    @include rxc-desktop-only {
      top: 50%;
      transform: translateY(-60%);
      width: 25% !important;
      right: 80px;
      padding: 52px 35px 52px 29px;
    }

    @include rxc-mobile-only {
      bottom: 50px;
      left: 50px;
      width: calc(100% - 50px) !important;
      padding: 18px 15px 35px 15px;
    }
  }

  &__title {
    color: white;
    font-family: "minerva-modern" !important;
    font-size: 28px !important;
    font-weight: 400;
    text-align: left;
    margin-bottom: 8px;
    line-height: 1.14;
    letter-spacing: 0.14px;

    @include rxc-mobile-only {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  &__body {
    font-family: "Sofia-Pro";
    font-size: 14px;
    font-weight: normal;
    color: white;
    text-align: left;
    line-height: 1.71;
    letter-spacing: 0.5px;

    & div {
      margin-bottom: 15px;
    }

    & ul {
      @include rxc-mobile-only {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        padding: 0 0 0 10px;
      }
    }

    & li {
      font-family: "Sofia-Pro";
      font-size: 16px;
      font-weight: normal;
      color: white;
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.5px;

      @include rxc-mobile-only {
        font-size: 12px;
        letter-spacing: 0;
      }
    }
  }
}

.LearnMoreModal {
  &__leftCarouselArrow {
    @include rxc-mobile-only {
      display: none;
    }

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='24' width='24' height='24' rx='12' transform='rotate(90 24 0)' fill='%23F0EAE2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7803 6.21967C14.4874 5.92678 14.0126 5.92678 13.7197 6.21967L8.46967 11.4697C8.17678 11.7626 8.17678 12.2374 8.46967 12.5303L13.7197 17.7803C14.0126 18.0732 14.4874 18.0732 14.7803 17.7803C15.0732 17.4874 15.0732 17.0126 14.7803 16.7197L10.0607 12L14.7803 7.28033C15.0732 6.98744 15.0732 6.51256 14.7803 6.21967Z' fill='%23222222'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    margin-right: 15px;
    margin-left: 0;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    &.invisible {
      visibility: hidden;
    }
  }

  &__rightCarouselArrow {
    @include rxc-mobile-only {
      display: none;
    }

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='24' width='24' height='24' rx='12' transform='rotate(-90 0 24)' fill='%23F0EAE2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.21967 17.7803C9.51256 18.0732 9.98744 18.0732 10.2803 17.7803L15.5303 12.5303C15.8232 12.2374 15.8232 11.7626 15.5303 11.4697L10.2803 6.21967C9.98744 5.92678 9.51256 5.92678 9.21967 6.21967C8.92678 6.51256 8.92678 6.98744 9.21967 7.28033L13.9393 12L9.21967 16.7197C8.92678 17.0126 8.92678 17.4874 9.21967 17.7803Z' fill='%23222222'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    margin-left: 15px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;

    &.invisible {
      visibility: hidden;
    }
  }

  &__container {
    // display: none;
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;
    padding-top: 100px;
    padding-bottom: 100px;
    backdrop-filter: blur(0.3rem);

    @include rxc-mobile-only {
      top: -50px;
    }

    /*         &__carouselMarginfirst .carousel-slider .slider-wrapper .slider  {   
            @media only screen and (min-width: 1200PX) {
                transform: translateX(15%);
                transition: 0.3s;
            }         
            
         & .carousel .control-dots {
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (min-width: 1200PX) {
                left: 35%;
                transform: translateX(-50%);
            }
            
        } 
            


        } */
    /*         &__carouselMarginlast{
            @media only screen and (min-width: 1200PX) {                
                transform: translateX(-15%);            
                transition: 0.3s;
            }
             & .carousel .control-dots {
                @media only screen and (min-width: 1200PX) {                    
                    left: 65%;
                    transform: translateX(-50%);
                }
            } 

            
        } */
    &__carouselMiddle {
      @media only screen and (min-width: 1200px) {
        transition: 0.3s;
      }

      /* & .carousel .control-dots {
                @media only screen and (min-width: 1200PX) {                    
                left: 50%;
                transform: translateX(-50%);
                }
            }  */
    }

    & li.slide {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;
      overflow: hidden;
      height: fit-content;

      @include rxc-mobile-only {
        padding-right: 0;
        height: clamp(28.4rem, -0rem + 80vh, 51.2rem);
      }

      /*             @media only screen and (max-device-width: 320px) {
                height: 76vh !important;
            }
 */
      & {
        border-radius: 8px;
      }

      & > div {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &::after :first-child {
          content: " ";
          box-shadow: 0 23.6px 47.1px 0 rgb(96 97 112 / 16%),
            0 5.9px 11.8px 0 rgb(40 41 61 / 4%);
          position: absolute;
          top: 0;
          left: 3.7%;
          right: 3.7%;
          bottom: 7%;
          border-radius: 8px;
        }
      }

      & video {
        @include rxc-desktop-only {
          width: 100%;
          height: 100%;
        }
        @include rxc-mobile-only {
          height: 100%;
        }
      }

      & img {
        height: 100%;
        width: 100%;

        @include rxc-mobile-only {
          width: auto;
        }
      }
    }
  }

  &__blur {
    display: none;
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 8;
    backdrop-filter: blur(10px);
  }

  &__dotnav {
    bottom: 45px !important;
    z-index: 9;
  }
}
