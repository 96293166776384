@import "../../mixins/global";

.StepContainer {
  &__container {
    @include rxc-mobile-only {
      min-height: 60vh;
      height: fit-content;
    }

    &:last-child {
      @include rxc-desktop-only {
        padding-bottom: 24px;
      }
    }

    @include rxc-desktop-only {
      margin: 0 60px 0 40px;
    }
  }
}
