@import "../../mixins/global";

.ScrollView {
  &__toBottom {
    width: 32px;
    height: 32px;
    position: fixed;
    right: 25%;
    bottom: 83px;
    z-index: 1;
    cursor: pointer;

    @include rxc-mobile-only {
      display: none;
    }
  }

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    background-color: #f1f2f2;

    @include rxc-mobile-only {
      padding-right: 0;
    }

    @include rxc-desktop-only {
      height: calc(100vh - 52px - 70px);
    }

    //RXPANEL-1466
    /* &::-webkit-scrollbar {
            @include rxc-desktop-only {
                display: none;
            }
        } */

    &.review {
      @include rxc-desktop-only {
        height: calc(100vh - 72px - 70px - 81px);
      }
    }
  }

  &__innerContainer {
    height: 100%;
    background-color: #f1f2f2;
    @include rxc-mobile-only {
      padding: 0 18px;
    }
  }

  &__trackVertical {
    position: absolute;
    width: 2px;
    top: 50%;
    transform: translate(0, -50%);
    height: 95%;
    max-height: 300px;
    right: 31px;
    border-radius: 5px;
    background-color: #d3d3d3;
    display: none;
  }

  &__thumbVertical {
    border-radius: 5px;
    background-color: #326fa8;
    box-shadow: 0 0 8px 0 #d7d7d7;
  }

  &__scrollbar {
    @include rxc-mobile-only {
      overflow-y: scroll !important;
    }

    @include rxc-desktop-only {
      flex: 1 1 50%;
      background-color: #f1f2f2;
    }
  }

  &__fade {
    content: "";
    background: linear-gradient(
      to top,
      rgba(251, 251, 251, 1) 0%,
      rgba(251, 251, 251, 0) 100%
    );
    position: fixed;
    width: 100%;
    height: 40px;

    @include rxc-mobile-only {
      bottom: 0px;
    }

    @include rxc-desktop-only {
      bottom: 71px;
    }
  }
}
